import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { CustomerDashboardUser } from '../../api/graphql/fragments/dashboardUser';
import { customerDashboardUserQuery, CustomerDashboardUserQueryResponse } from '../../api/graphql/queries/dashbordUser';

export function useCustomerDashboardUser(): CustomerDashboardUser | undefined {
    const queryResponse = useQuery<CustomerDashboardUserQueryResponse>(customerDashboardUserQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const user = React.useMemo(() => queryResponse?.data?.customerDashboardUser, [queryResponse]);
    return user;
}
