import { ApolloClient } from '../client';
import { confirmTransactionInCache } from './confirmTransaction';
import { declineTransactionInCache } from './declineTransaction';

import gql from 'graphql-tag';

export const importTransactionsFromCSVMutation = gql`
    mutation ImportTransactionsFromCSV($action: String!, $filename: String) {
        importTransactionsFromCSV(action: $action, filename: $filename)
    }
`;

export type ImportTransactionsFromCSVMutationVariables =
    | {
          action: 'getUploadURL';
      }
    | {
          action: 'processUploadedFile';
          filename: string;
      };

export interface ImportTransactionsFromCSVMutationResponse {
    __typename: 'Mutation';
    importTransactionsFromCSV: string;
}

export type ImportTransactionsFromCSVError =
    | { error: 'MissingFile' }
    | { error: 'IllFormedFile' }
    | { error: 'MissingField' }
    | { error: 'IllFormedLine'; lineIndex: number; transactionId?: string }
    | { error: 'MissingDeclinedReason'; transactionId?: string }
    | { error: 'TransactionNotFound'; transactionId: string }
    | { error: 'TransactionNotPending'; transactionId: string };

export type ImportTransactionsFromCSVOutput =
    | {
          action: 'getUploadURL';
          filename: string;
          url: string;
      }
    | {
          action: 'processUploadedFile';
          confirmedTransactionIds?: string[];
          declinedTransactionIds?: string[];
          unhandledTransactionIds?: string[];
          errors: ImportTransactionsFromCSVError[];
      };

export async function getUploadURL(apolloClient: ApolloClient): Promise<{ filename: string; url: string }> {
    const mutationResponse = await apolloClient.mutate<
        ImportTransactionsFromCSVMutationResponse,
        ImportTransactionsFromCSVMutationVariables
    >({ mutation: importTransactionsFromCSVMutation, variables: { action: 'getUploadURL' } });
    if (!mutationResponse.data?.importTransactionsFromCSV) throw Error('Empty response');
    const parsedMutationResponse: ImportTransactionsFromCSVOutput = JSON.parse(
        mutationResponse.data?.importTransactionsFromCSV
    );
    if (parsedMutationResponse.action !== 'getUploadURL') throw Error('Incorrect response');
    return parsedMutationResponse;
}

export type ProcessUploadedFileResult = {
    confirmedTransactionIds?: string[];
    declinedTransactionIds?: string[];
    unhandledTransactionIds?: string[];
    errors: ImportTransactionsFromCSVError[];
};

export async function processUploadedFile(apolloClient: ApolloClient, filename: string): Promise<ProcessUploadedFileResult> {
    const mutationResponse = await apolloClient.mutate<
        ImportTransactionsFromCSVMutationResponse,
        ImportTransactionsFromCSVMutationVariables
    >({ mutation: importTransactionsFromCSVMutation, variables: { action: 'processUploadedFile', filename } });
    if (!mutationResponse.data?.importTransactionsFromCSV) throw Error('Empty response');
    const parsedMutationResponse: ImportTransactionsFromCSVOutput = JSON.parse(
        mutationResponse.data?.importTransactionsFromCSV
    );
    if (parsedMutationResponse.action !== 'processUploadedFile') throw Error('Incorrect response');
    parsedMutationResponse.confirmedTransactionIds?.map((transactionId) =>
        confirmTransactionInCache(apolloClient, transactionId)
    );
    parsedMutationResponse.declinedTransactionIds?.map((transactionId) =>
        declineTransactionInCache(apolloClient, transactionId)
    );
    return parsedMutationResponse;
}
