// To name the colors we use this website: http://chir.ag/projects/name-that-color/

export const whiteIce: string = '#F0FCF3';
export const emerald: string = '#30DA55';

export const white: string = '#ffffff';
export const alabaster: string = '#fcfcfc';
export const wildSand: string = '#f5f5f5';
export const concrete: string = '#f2f2f2';
export const mercury: string = '#e8e8e8';
export const alto: string = '#cccccc';
export const silver: string = '#bbbbbb';
export const silverChalice: string = '#aaaaaa';
export const grayLight: string = '#919191';
export const gray: string = '#888888';
export const emperor: string = '#535353';
export const tundora: string = '#4c4c4c';
export const codGray: string = '#111111';
export const black: string = '#000000';

export const athensGrayLight = '#FBFBFC';
export const athensGray = '#F7F8FA';
export const athensGrayDark = '#ECEEF3';
export const ghost = '#C9CCD3';
export const frenchGray = '#B7BCC7';
export const osloGray = '#7F8590';
export const manatee: string = '#8F949D';

export const fairPink: string = '#FFECEC';
export const radicalRed: string = '#FF396D';

export const lemonChiffon: string = '#fff8cc';
export const scotchMist: string = '#FFF9DC';
export const candlelight: string = '#ffd814';
export const treePoppy: string = '#FFA114';

export const whisper: string = '#F2F3F8';
export const linkWaterLight: string = '#F5F8FC';
export const linkWater: string = '#DCE6F2';
export const dodgerBlue: string = '#4D94FF';
export const indigo: string = '#517DCD';
export const jacksonsPurple: string = '#2A2588';

const color = {
    whiteIce,
    emerald,
    white,
    alabaster,
    wildSand,
    concrete,
    mercury,
    alto,
    silver,
    silverChalice,
    grayLight,
    gray,
    emperor,
    tundora,
    codGray,
    black,
    athensGrayLight,
    athensGray,
    athensGrayDark,
    ghost,
    frenchGray,
    manatee,
    osloGray,
    fairPink,
    radicalRed,
    lemonChiffon,
    scotchMist,
    candlelight,
    treePoppy,
    whisper,
    linkWaterLight,
    linkWater,
    dodgerBlue,
    indigo,
    jacksonsPurple,
};

export default color;
