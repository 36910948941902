import gql from 'graphql-tag';
import { DashboardType } from '../../../lib/metabase/metabase';

export const metabaseDashboardSignedUrlQuery = gql`
    query MetabaseDashboardSignedUrlQuery($dashboardType: String!) {
        customer {
            metabaseDashboardSignedUrl(dashboardType: $dashboardType)
        }
    }
`;

export type MetabaseDashboardSignedUrlQueryResponse = {
    __typename: 'Query';
    customer: {
        __typename: 'Customer';
        metabaseDashboardSignedUrl: string | null;
    } | null;
};

export type MetabaseDashboardSignedUrlQueryVariables = {
    dashboardType: DashboardType;
};
