import React from 'react';
import { StyleSheet, Text, Image, TouchableOpacity, View } from 'react-native';
import moment from 'moment';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { localeTexts, dateRangeLocale } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import ModalContainer from './ModalContainer';

const texts = localeTexts.home.datePicker;

const calendarIcon = { uri: '/assets/images/icons/calendar-gray.svg' };

export function DatePicker({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    minDate,
    maxDate,
    minDateRangeInDays,
}: {
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
    minDate: string;
    maxDate: string;
    minDateRangeInDays: number;
}) {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    return (
        <TouchableOpacity style={styles.container} onPress={() => setIsModalVisible(true)}>
            <Image source={calendarIcon} style={{ height: 16, width: 16, resizeMode: 'contain' }} />
            <Text style={styles.text}>{texts.date({ startDate, endDate })}</Text>
            {isModalVisible ? (
                <Modal
                    onClose={() => setIsModalVisible(false)}
                    {...{ startDate, setStartDate, endDate, setEndDate, minDate, maxDate, minDateRangeInDays }}
                />
            ) : null}
        </TouchableOpacity>
    );
}

function Modal({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onClose,
    minDate,
    maxDate,
    minDateRangeInDays,
}: {
    onClose: () => void;
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
    minDate: string;
    maxDate: string;
    minDateRangeInDays: number;
}) {
    const [dateRangeState, setDateRangeState] = React.useState<Range[]>([
        { startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate(), key: 'selection' },
    ]);
    const onConfirm = () => {
        if (dateRangeState[0]?.startDate && dateRangeState[0]?.endDate) {
            let endDate = moment(dateRangeState[0].endDate).format('YYYY-MM-DD');
            endDate = endDate <= maxDate ? endDate : maxDate;
            let startDate = moment(dateRangeState[0].startDate).format('YYYY-MM-DD');
            const maxStartDate = moment(endDate).subtract(minDateRangeInDays, 'days').format('YYYY-MM-DD');
            startDate = startDate <= maxStartDate ? startDate : maxStartDate;
            startDate = startDate >= minDate ? startDate : minDate;
            setStartDate(startDate);
            setEndDate(endDate);
        }
        onClose();
    };
    return (
        <ModalContainer cannotClose>
            <View style={styles.containerModal}>
                <DateRange
                    editableDateInputs={true}
                    onChange={(item) => 'selection' in item && setDateRangeState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeState}
                    rangeColors={[color.emerald]}
                    locale={dateRangeLocale.fr}
                    dateDisplayFormat={'d MMM yyyy'}
                    disabledDay={(date) =>
                        moment(date).format('YYYY-MM-DD') > maxDate || moment(date).format('YYYY-MM-DD') < minDate
                    }
                />
                <TouchableOpacity style={styles.containerModalButton} onPress={onConfirm}>
                    <Text style={styles.textModalButton}>{texts.confirmButton}</Text>
                </TouchableOpacity>
            </View>
        </ModalContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
        paddingHorizontal: 16,
        backgroundColor: color.athensGray,
        borderRadius: 8,
    },
    containerModal: {
        padding: 20,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerModalButton: {
        alignSelf: 'flex-end',
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.emerald,
        borderRadius: 48,
    },
    text: {
        marginLeft: 8,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    textModalButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
});
