import * as React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Image, ActivityIndicator, ScrollView } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';
import { useDropzone } from 'react-dropzone';

import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import {
    getUploadURL,
    processUploadedFile,
    ProcessUploadedFileResult,
} from '../../api/graphql/mutations/importTransactionsFromCSV';
import ModalContainer from '../common/ModalContainer';
import HighlightedText from '../common/HighlightedText';

const texts = localeTexts.home.transactions;

const crossIcon = { uri: '/assets/images/icons/cross-black-thin.svg' };
const uploadIcon = { uri: '/assets/images/icons/cloud-upload-green.svg' };
const fileIcon = { uri: '/assets/images/icons/data-file.svg' };
const warningIcon = { uri: '/assets/images/icons/alert-orange.svg' };

export default function TransactionsImportButton() {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    return (
        <TouchableOpacity style={styles.containerButton} onPress={() => setIsModalVisible(true)}>
            <Text style={styles.textButton}>{texts.importButton}</Text>
            {isModalVisible ? <TransactionsImportModal onClose={() => setIsModalVisible(false)} /> : null}
        </TouchableOpacity>
    );
}

function TransactionsImportModal({ onClose }: { onClose: () => void }) {
    const apolloClient = useApolloClient();
    const [file, setFile] = React.useState<File | undefined>(undefined);
    const [isFileSelected, setIsFileSelected] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [processingResult, setProcessingResult] = React.useState<ProcessUploadedFileResult | undefined>(undefined);
    const onPressNext = async () => {
        if (processingResult) onClose();
        else if (!isFileSelected && file) setIsFileSelected(true);
        else if (!isLoading && isFileSelected && file) {
            setIsLoading(true);
            const { url, filename } = await getUploadURL(apolloClient);
            const fileContent = await file.text();
            await fetch(url, { method: 'PUT', body: fileContent });
            const processingResult = await processUploadedFile(apolloClient, filename);
            setIsLoading(false);
            setProcessingResult(processingResult);
        }
    };
    return (
        <ModalContainer cannotClose>
            <View style={styles.containerModal}>
                <View style={styles.containerModalHeader}>
                    <Text style={styles.textModalTitle}>{texts.importModal.title}</Text>
                    <TouchableOpacity onPress={onClose}>
                        <Image source={crossIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    </TouchableOpacity>
                </View>
                <View style={styles.containerModalContent}>
                    {!isFileSelected ? (
                        <>
                            <HighlightedText
                                style={styles.textModalIndications}
                                highlightedStyle={styles.textModalIndicationsHighlighted}>
                                {texts.importModal.indications}
                            </HighlightedText>
                            <FileDropZone {...{ file, setFile }} />
                        </>
                    ) : !processingResult ? (
                        <>
                            <Text style={styles.textModalConfirmationMessage}>{texts.importModal.confirmation.message}</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <Image source={warningIcon} style={{ width: 15, height: 15, resizeMode: 'contain' }} />
                                <Text style={styles.textModalConfirmationWarning}>
                                    {texts.importModal.confirmation.warning}
                                </Text>
                            </View>
                        </>
                    ) : (
                        <FileProcessingResult {...{ result: processingResult }} />
                    )}
                </View>
                {!processingResult ? (
                    <View style={styles.containerModalButtons}>
                        <ModalButton onPress={onClose} isSecondary text={texts.importModal.cancelButton} />
                        <View style={{ width: 8 }} />
                        <ModalButton
                            onPress={onPressNext}
                            disabled={!file}
                            text={texts.importModal.nextButton}
                            isLoading={isLoading}
                        />
                    </View>
                ) : (
                    <View style={styles.containerModalButtons}>
                        <ModalButton onPress={onClose} text={texts.importModal.closeButton} />
                    </View>
                )}
            </View>
        </ModalContainer>
    );
}

function FileDropZone({ file, setFile }: { file: File | undefined; setFile: (value: File | undefined) => void }) {
    const { open, getRootProps, getInputProps, acceptedFiles } = useDropzone({ accept: '.csv', maxFiles: 1 });
    React.useEffect(() => {
        if (acceptedFiles.length) setFile(acceptedFiles[0]);
    }, [setFile, acceptedFiles]);
    if (!file)
        return (
            <div {...getRootProps({ style: {} })}>
                <View style={styles.containerFileDropZone}>
                    <input {...getInputProps()} />
                    <View style={styles.containerFileDropZoneUploadIcon}>
                        <Image source={uploadIcon} style={{ width: 28, height: 28, resizeMode: 'contain' }} />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity style={styles.containerModalSecondaryButton} onPress={open}>
                            <Text style={styles.textModalSecondaryButton}>{texts.importModal.fileDropZone.button}</Text>
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.textFileDropZoneUpload}>{texts.importModal.fileDropZone.text}</Text>
                </View>
            </div>
        );
    else
        return (
            <View style={styles.containerFileDropZone}>
                <View style={styles.containerFileDropZoneFileTop}>
                    <Image source={fileIcon} style={{ width: 32, height: 32, resizeMode: 'contain' }} />
                </View>
                <View style={styles.containerFileDropZoneFileBottom}>
                    <Text style={styles.textFileDropZoneFileName} numberOfLines={1}>
                        {file.name}
                    </Text>
                </View>
            </View>
        );
}

function FileProcessingResult({ result }: { result: ProcessUploadedFileResult }) {
    return (
        <>
            <View style={styles.containerResultLine}>
                <Text style={styles.textResultLabel}>{texts.importModal.result.numberOfConfirmedTransactions}</Text>
                <Text style={styles.textResultValue}>{result.confirmedTransactionIds?.length || 0}</Text>
            </View>
            <View style={styles.containerResultLine}>
                <Text style={styles.textResultLabel}>{texts.importModal.result.numberOfDeclinedTransactions}</Text>
                <Text style={styles.textResultValue}>{result.declinedTransactionIds?.length || 0}</Text>
            </View>
            <View style={styles.containerResultLine}>
                <Text style={styles.textResultLabel}>{texts.importModal.result.numberOfUnhandledTransactions}</Text>
                <Text style={styles.textResultValue}>{result.unhandledTransactionIds?.length || 0}</Text>
            </View>
            <ScrollView style={{ maxHeight: 200 }}>
                {result.errors?.map((error, index) => (
                    <Text style={styles.textResultError} key={index}>
                        ❌ {texts.importModal.result.error({ error })}
                    </Text>
                ))}
            </ScrollView>
        </>
    );
}

function ModalButton({
    onPress,
    isSecondary,
    text,
    disabled,
    isLoading,
}: {
    onPress: () => void;
    isSecondary?: boolean;
    text: string;
    disabled?: boolean;
    isLoading?: boolean;
}) {
    return (
        <TouchableOpacity
            {...{ onPress }}
            disabled={disabled || isLoading}
            style={!isSecondary && !disabled ? styles.containerModalPrimaryButton : styles.containerModalSecondaryButton}>
            {isLoading ? (
                <ActivityIndicator color={color.white} size={'small'} />
            ) : (
                <Text style={!isSecondary && !disabled ? styles.textModalPrimaryButton : styles.textModalSecondaryButton}>
                    {text}
                </Text>
            )}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    containerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        paddingHorizontal: 16,
        borderRadius: 48,
        backgroundColor: color.emerald,
        marginLeft: 8,
    },
    textButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
    containerModal: {
        width: 520,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerModalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 64,
        paddingHorizontal: 24,
        borderBottomWidth: 1,
        borderBottomColor: color.athensGrayDark,
    },
    containerModalContent: {
        padding: 24,
    },
    containerModalButtons: {
        alignSelf: 'flex-end',
        flexDirection: 'row',
        marginRight: 24,
        marginBottom: 24,
    },
    containerModalPrimaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.emerald,
        borderRadius: 48,
    },
    containerModalSecondaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.athensGray,
        borderRadius: 48,
    },
    containerFileDropZone: {
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: color.alto,
        borderWidth: 1,
        borderRadius: 8,
        height: 200,
        borderStyle: 'dashed',
    },
    containerFileDropZoneUploadIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: color.whiteIce,
        marginBottom: 16,
    },
    containerFileDropZoneFileTop: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 112,
        width: 152,
        backgroundColor: color.athensGray,
        borderWidth: 1,
        borderColor: color.athensGrayDark,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    containerFileDropZoneFileBottom: {
        height: 33,
        width: 152,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: color.athensGrayDark,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        justifyContent: 'center',
        paddingHorizontal: 8,
    },
    containerResultLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    textModalTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.tundora,
    },
    textModalIndications: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.osloGray,
        marginBottom: 24,
    },
    textModalIndicationsHighlighted: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.emerald,
    },
    textModalConfirmationMessage: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
        marginBottom: 16,
    },
    textModalConfirmationWarning: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
        marginLeft: 8,
    },
    textModalPrimaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
    textModalSecondaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    textFileDropZoneUpload: {
        marginTop: 16,
        fontFamily: font.ambitRegular,
        fontSize: 12,
        color: color.osloGray,
    },
    textFileDropZoneFileName: {
        fontFamily: font.ambitRegular,
        fontSize: 8,
        color: color.tundora,
    },
    textResultLabel: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.frenchGray,
    },
    textResultValue: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    textResultError: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.radicalRed,
    },
});
