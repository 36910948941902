import { getCognitoConfiguration } from '../../api/config';

export function checkIsSignedIn(): boolean {
    const cognitoConfiguration = getCognitoConfiguration();
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        else if (key.startsWith(`CognitoIdentityServiceProvider.${cognitoConfiguration.userPoolWebClientId}`)) return true;
    }
    return false;
}

export function getUserId(): string | undefined {
    const cognitoConfiguration = getCognitoConfiguration();
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        else {
            const match = key.match(
                `CognitoIdentityServiceProvider\\.${cognitoConfiguration.userPoolWebClientId}\\.(.*)\\.refreshToken`
            );
            if (match?.[1]) return match[1];
        }
    }
    return undefined;
}
