import { Customer, customerFragment } from '../fragments/customer';

import gql from 'graphql-tag';

export const customerQuery = gql`
    query CustomerQuery {
        customer {
            ...Customer
        }
    }
    ${customerFragment}
`;

export type CustomerQueryResponse = {
    __typename: 'Query';
    customer: Customer | null;
};
