import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import { ApolloClient } from '../api/graphql/client';
import { checkIsSignedIn } from '../lib/common/cognito';
import AuthNavigator from './auth/AuthNavigator';
import HomeNavigator from './home/HomeNavigator';

function RootNavigator({ apolloClient }: { apolloClient: ApolloClient | undefined }) {
    return (
        <Router>
            <Switch>
                <Route
                    path="/auth"
                    render={() => {
                        if (!checkIsSignedIn()) return <AuthNavigator />;
                        else return <Redirect to={'/home'} />;
                    }}
                />
                <Route
                    path="/home"
                    render={() => {
                        if (checkIsSignedIn()) {
                            if (apolloClient)
                                return (
                                    <ApolloProvider client={apolloClient}>
                                        <HomeNavigator />
                                    </ApolloProvider>
                                );
                            return null;
                        } else return <Redirect to={'/auth'} />;
                    }}
                />
                <Route path="*">
                    <Redirect to={'/auth'} />
                </Route>
            </Switch>
        </Router>
    );
}

export default RootNavigator;
