import gql from 'graphql-tag';

export const customerFragment = gql`
    fragment Customer on Customer {
        customerId
        customerName
        needsToApproveTransactions
        needsHashedEmails
    }
`;

export interface Customer {
    __typename: 'Customer';
    customerId: string;
    customerName: string;
    needsToApproveTransactions: boolean | null;
    needsHashedEmails: boolean | null;
}

export const dataIdFromCustomer = (customer?: Customer) => {
    return `Customer:current`;
};
