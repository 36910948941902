import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, TextInput, View, Image } from 'react-native';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';

import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import Button from '../../components/common/Button';
import { getDevStackMode, toggleDevStackMode } from '../../lib/common/devStackMode';
import { usePrevious } from '../../lib/common/utilHooks';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };
const eyeIcon = { uri: '/assets/images/icons/eye.svg' };
const eyeSlashIcon = { uri: '/assets/images/icons/eye-slash.svg' };

const texts = localeTexts.auth.signIn;

export default function SignInPage() {
    const history = useHistory();
    const size = useWindowSize();
    const devStackMode = getDevStackMode();
    const [signIn, isLoading, error, clearError] = useSignIn();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const previousEmail = usePrevious(email);
    const previousPassword = usePrevious(password);
    React.useEffect(() => {
        if (email.length !== previousEmail?.length || password.length !== previousPassword?.length) clearError();
    }, [email, password, previousEmail, previousPassword, clearError]);
    const disabled = !email || !password;
    const onPress = () => signIn(email, password);
    return (
        <View style={[styles.container, { height: size.height }, devStackMode && { backgroundColor: color.fairPink }]}>
            <View style={styles.containerLogo}>
                <Image source={logo} style={styles.imageLogo} />
            </View>
            <View style={styles.containerContent}>
                <Text style={styles.textTitle}>{texts.title}</Text>
                {error ? <ErrorMessage {...{ error }} /> : null}
                <EmailInput {...{ email, setEmail, isLoading }} />
                <PasswordInput {...{ password, setPassword, isLoading, onPress }} />
                {/* <TouchableOpacity onPress={() => history.push(`/auth/reset-password?email=${encodeURIComponent(email)}`)}>
                    <Text style={styles.textResetPasswordButton}>{texts.resetPassword}</Text>
                </TouchableOpacity> */}
                <Button {...{ isLoading, onPress, disabled }} textStyle={styles.textButton} height={40}>
                    {texts.button}
                </Button>
            </View>
            <Text style={styles.textSignUp}>{texts.signUp.text}</Text>
            <TouchableOpacity onPress={() => history.push('/auth/sign-up')}>
                <Text style={styles.textSignUpButton}>{texts.signUp.button}</Text>
            </TouchableOpacity>
        </View>
    );
}

enum SignInWithEmailError {
    EmailAddressUnknown = 'EmailAddressUnknown',
    Other = 'Other',
}

const useSignIn: () => [
    (email: string, password: string) => void,
    boolean,
    SignInWithEmailError | undefined,
    () => void
] = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<SignInWithEmailError | undefined>();
    const signIn = async (email: string, password: string) => {
        if (email === 'switchstack') {
            toggleDevStackMode();
            window.location.reload();
        } else if (email.length > 0 && password.length > 0) {
            if (!isLoading) {
                setIsLoading(true);
                try {
                    await Auth.signIn(email, password);
                    window.location.reload();
                } catch (error: any) {
                    if (error?.code === 'NotAuthorizedException') setError(SignInWithEmailError.EmailAddressUnknown);
                    else {
                        console.log(error);
                        setError(SignInWithEmailError.Other);
                    }
                    setIsLoading(false);
                }
            }
        }
    };
    const clearError = () => setError(undefined);
    return [signIn, isLoading, error, clearError];
};

const EmailInput = ({
    email,
    setEmail,
    isLoading,
}: {
    email: string;
    setEmail: (email: string) => void;
    isLoading: boolean;
}) => (
    <View style={[email ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
        <TextInput
            style={styles.textInput}
            placeholder={texts.email}
            autoCorrect={false}
            autoCapitalize={'none'}
            keyboardType={'email-address'}
            returnKeyType="next"
            autoFocus={true}
            editable={!isLoading}
            onChangeText={setEmail}
            placeholderTextColor={color.frenchGray}
        />
    </View>
);

const PasswordInput = ({
    password,
    setPassword,
    isLoading,
    onPress,
}: {
    password: string;
    setPassword: (password: string) => void;
    isLoading: boolean;
    onPress: () => void;
}) => {
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    return (
        <View style={[password ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.password}
                autoCorrect={false}
                autoCapitalize={'none'}
                secureTextEntry={hidePassword}
                underlineColorAndroid="transparent"
                returnKeyType="go"
                editable={!isLoading}
                onChangeText={setPassword}
                onSubmitEditing={onPress}
                placeholderTextColor={color.frenchGray}
            />
            <TouchableOpacity
                onPress={() => setHidePassword(!hidePassword)}
                activeOpacity={0.8}
                style={{ justifyContent: 'center', position: 'absolute', right: 5, top: 5 }}>
                {hidePassword ? (
                    <Image source={eyeIcon} style={styles.imageEyeIcon} />
                ) : (
                    <Image source={eyeSlashIcon} style={styles.imageEyeIcon} />
                )}
            </TouchableOpacity>
        </View>
    );
};

const ErrorMessage = ({ error }: { error: SignInWithEmailError }) => {
    let message: string = '';
    if (error === SignInWithEmailError.EmailAddressUnknown) message = texts.error.wrongCredentials;
    else message = texts.error.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.linkWaterLight,
    },
    containerLogo: {
        position: 'absolute',
        top: 54,
    },
    containerContent: {
        width: 440,
        paddingHorizontal: 32,
        paddingVertical: 48,
        backgroundColor: color.white,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.06,
        shadowRadius: 12,
    },
    containerErrorMessage: {
        backgroundColor: color.fairPink,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginBottom: 16,
    },
    containerTextInput: {
        flexDirection: 'row',
        marginHorizontal: 4,
        marginBottom: 24,
    },
    textTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 20,
        color: color.codGray,
        marginBottom: 24,
    },
    textErrorMessage: {
        color: color.radicalRed,
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitRegular,
        color: color.tundora,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textResetPasswordButton: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
        marginBottom: 24,
    },
    textButton: {
        fontSize: 14,
    },
    textSignUp: {
        marginTop: 24,
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.manatee,
    },
    textSignUpButton: {
        marginTop: 8,
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.emerald,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.tundora,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
    imageEyeIcon: {
        width: 25,
        height: 25,
        resizeMode: 'contain',
    },
    imageLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
});
