import { CustomerTransaction, customerTransactionFragment } from '../fragments/transactions';

import gql from 'graphql-tag';

export const customerTransactionsQuery = gql`
    query CustomerTransactionsQuery($startDate: String!, $endDate: String, $customerCampaignId: String) {
        customer {
            transactions(startDate: $startDate, endDate: $endDate, customerCampaignId: $customerCampaignId) {
                items {
                    ...CustomerTransaction
                }
                nextToken
            }
        }
    }
    ${customerTransactionFragment}
`;

export type CustomerTransactionsQueryResponse = {
    __typename: 'Query';
    customer: {
        transactions: {
            items: CustomerTransaction[];
            nextToken: string | null;
        };
    } | null;
};

export type CustomerTransactionsQueryVariables = {
    startDate: string;
    endDate?: string;
    customerCampaignId?: string;
};
