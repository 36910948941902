import React from 'react';
import { View } from 'react-native';
import { Switch, Redirect, Route, useRouteMatch, useLocation } from 'react-router-dom';

import ProfileNavigationBar from '../../pages/home/profile/ProfileNavigationBar';
import UserInfoPage from '../../pages/home/profile/UserInfoPage';

function ProfileNavigator() {
    const location = useLocation();
    let { path } = useRouteMatch();
    return (
        <View>
            <ProfileNavigationBar />
            <Switch location={location}>
                <Route exact path={path}>
                    <Redirect to={`${path}/user-info`} />
                </Route>
                <Route exact path={`${path}/user-info`}>
                    <UserInfoPage />
                </Route>
            </Switch>
        </View>
    );
}

export default ProfileNavigator;
