import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { Auth } from 'aws-amplify';
import { ApolloClient as ApolloClientBase, ApolloLink, createHttpLink } from '@apollo/client';
import { cache, setupCachePersistor } from './cache';

export type ApolloClient = ApolloClientBase<object>;

export async function buildApolloClientAndRestoreCache(url: string, region: string): Promise<ApolloClient> {
    const auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS;
        jwtToken: () => Promise<string>;
    } = {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
            try {
                const currentSession = await Auth.currentSession();
                return currentSession.getIdToken().getJwtToken();
            } catch (error) {
                return '';
            }
        },
    };
    const httpLink = createHttpLink({ uri: url });
    const link = ApolloLink.from([createAuthLink({ url, region, auth }), httpLink]); // For debugging: ApolloLink.from([logLink, createAuthLink({ url, region, auth }), httpLink]);
    const client: unknown = new ApolloClientBase({ cache, link });
    const typedClient = client as ApolloClient;
    await setupCachePersistor();
    return typedClient;
}

export const logLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
        console.log('GraphQL operation:', operation.operationName, operation.variables);
        return result;
    });
});
