import { StyleSheet, View, Text, ActivityIndicator, Image } from 'react-native';

import { localeTexts } from '../../Locales';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { font } from '../../style/text';
import { DashboardType, useMetabaseDashboardSignedUrl } from '../../lib/metabase/metabase';

const texts = localeTexts.home;
const emptyImage = { uri: '/assets/images/illustrations/undraw-empty.svg' };

export default function MetabaseInsightsPage() {
    const size = useWindowSize();
    const iframeUrl = useMetabaseDashboardSignedUrl(DashboardType.insights);
    return (
        <View style={[styles.container, { height: (size.height || 0) - NAVIGATION_BAR_HEIGHT, width: size.width }]}>
            {iframeUrl === undefined ? (
                <ActivityIndicator size={'large'} color={color.frenchGray} />
            ) : !iframeUrl.length ? (
                <View style={styles.containerEmptyInsights}>
                    <Image source={emptyImage} style={{ width: 200, height: 200, resizeMode: 'contain' }} />
                    <Text style={styles.textEmptyInsightsTitle}>{texts.metabaseInsights.title}</Text>
                </View>
            ) : (
                <View>
                    <iframe
                        title="Insights Metabase Dashboard iframe"
                        src={iframeUrl}
                        frameBorder="0"
                        width="100%"
                        height="700"
                        allowTransparency></iframe>
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 48,
        paddingTop: 60,
    },
    containerHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
    containerEmptyInsights: {
        marginTop: 175,
        alignSelf: 'center',
        alignItems: 'center',
        width: 340,
    },
    textTitle: {
        fontFamily: font.ambitRegular,
        fontSize: 24,
        color: color.tundora,
    },
    textEmptyInsightsTitle: {
        marginTop: 35,
        fontFamily: font.ambitRegular,
        fontSize: 20,
        lineHeight: 32,
        color: color.tundora,
    },
});
