import * as React from 'react';
import { View, StyleSheet, ViewStyle, TextStyle, Text } from 'react-native';

import { localeTexts } from '../../Locales';
import HighlightedText from '../common/HighlightedText';
import { font } from '../../style/text';
import color from '../../style/color';

const PASSWORD_MIN_CHARACTERS = 10;
const PASSWORD_MAX_CHARACTERS = 50;
const PASSWORD_MIN_LOWER_CASE = 1;
const PASSWORD_MIN_UPPER_CASE = 1;
const PASSWORD_MIN_NUMBERS = 1;
const texts = localeTexts.auth.signUp.passwordRequirements;

const PasswordRequirements = ({
    passwordRequirementsChecks,
    style,
    isInline,
}: {
    passwordRequirementsChecks: PasswordRequirementsChecks;
    style?: TextStyle & ViewStyle;
    isInline?: boolean;
}) => {
    const Requirements = () => (
        <>
            <PasswordRequirementLine isChecked={passwordRequirementsChecks.minCharacters}>
                {texts.minCharacters({ minCharacters: PASSWORD_MIN_CHARACTERS })}
            </PasswordRequirementLine>
            <PasswordRequirementLine isChecked={passwordRequirementsChecks.minLowerCase}>
                {texts.minLowerCase({ minLowerCase: PASSWORD_MIN_LOWER_CASE })}
            </PasswordRequirementLine>
            <PasswordRequirementLine isChecked={passwordRequirementsChecks.minUpperCase}>
                {texts.minUpperCase({ minUpperCase: PASSWORD_MIN_UPPER_CASE })}
            </PasswordRequirementLine>
            <PasswordRequirementLine isChecked={passwordRequirementsChecks.minNumbers}>
                {texts.minNumbers({ minNumbers: PASSWORD_MIN_NUMBERS })}
            </PasswordRequirementLine>
        </>
    );
    if (isInline)
        return (
            <Text style={style}>
                <Requirements />
            </Text>
        );
    else
        return (
            <View style={[styles.containerPasswordRequirements, style]}>
                <Requirements />
            </View>
        );
};

export default PasswordRequirements;

const PasswordRequirementLine = ({ isChecked, children }: { isChecked: boolean; children: string }) => (
    <HighlightedText
        style={styles.textPasswordRequirement}
        highlightedStyle={{
            color: isChecked ? color.emerald : color.manatee,
            fontFamily: font.ambitSemiBold,
        }}>
        {children}
    </HighlightedText>
);

export interface PasswordRequirementsChecks {
    minCharacters: boolean;
    minLowerCase: boolean;
    minUpperCase: boolean;
    minNumbers: boolean;
    all: boolean;
}

export function checkPasswordRequirements(password: string): PasswordRequirementsChecks {
    const minCharacters = password.length >= PASSWORD_MIN_CHARACTERS && password.length <= PASSWORD_MAX_CHARACTERS;
    const minLowerCase = password.length - password.replace(/[a-z]/g, '').length >= PASSWORD_MIN_LOWER_CASE;
    const minUpperCase = password.length - password.replace(/[A-Z]/g, '').length >= PASSWORD_MIN_UPPER_CASE;
    const minNumbers = password.length - password.replace(/[0-9]/g, '').length >= PASSWORD_MIN_NUMBERS;
    const checks: PasswordRequirementsChecks = {
        minCharacters,
        minLowerCase,
        minUpperCase,
        minNumbers,
        all: minCharacters && minLowerCase && minUpperCase && minNumbers,
    };
    return checks;
}

export function usePasswordRequirementsChecks(password: string): PasswordRequirementsChecks {
    const [passwordRequirementsChecks, setPasswordRequirementsChecks] = React.useState<PasswordRequirementsChecks>(
        checkPasswordRequirements(password)
    );
    React.useEffect(() => {
        setPasswordRequirementsChecks(checkPasswordRequirements(password));
    }, [password]);
    return passwordRequirementsChecks;
}

const styles = StyleSheet.create({
    containerPasswordRequirements: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
    },
    textPasswordRequirement: {
        color: color.frenchGray,
        fontFamily: font.ambitRegular,
        fontSize: 12,
    },
});
