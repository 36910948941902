import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Customer } from '../../api/graphql/fragments/customer';
import { customerQuery, CustomerQueryResponse } from '../../api/graphql/queries/customer';

export function useCustomer(): [Customer | undefined, boolean] {
    const queryResponse = useQuery<CustomerQueryResponse>(customerQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const customer = React.useMemo(() => queryResponse?.data?.customer || undefined, [queryResponse]);
    return [customer, queryResponse.loading];
}
