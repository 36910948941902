import { View } from 'react-native';
import { Route, Switch, useLocation, useRouteMatch, Redirect } from 'react-router-dom';

import { useCustomer } from '../../lib/customer/customer';
import ProfileNavigator from './ProfileNavigator';
import NavigationPanel from '../../pages/home/NavigationPanel';
import TransactionsPage from '../../pages/home/TransactionsPage';
import NoCustomerPage from '../../pages/home/NoCustomerPage';
import MetabaseInsightsPage from '../../pages/home/MetabaseInsightsPage';
import MetabasePerformancePage from '../../pages/home/MetabasePerformancePage';

function HomeNavigator() {
    const location = useLocation();
    let { path } = useRouteMatch();
    const [customer, isCustomerLoading] = useCustomer();
    return (
        <View style={{ minHeight: '100vh' }}>
            <NavigationPanel />
            <Switch location={location}>
                <Route path={`${path}/profile`}>
                    <ProfileNavigator />
                </Route>
                <Route path={`${path}/campaigns`}>
                    {!isCustomerLoading && !customer ? <NoCustomerPage /> : <MetabasePerformancePage />}
                </Route>
                <Route path={`${path}/transactions`}>
                    {!isCustomerLoading && !customer ? <NoCustomerPage /> : <TransactionsPage />}
                </Route>
                <Route path={`${path}/insights`}>
                    {!isCustomerLoading && !customer ? <NoCustomerPage /> : <MetabaseInsightsPage />}
                </Route>
                <Route path="*">
                    <Redirect to={`${path}/campaigns`} />
                </Route>
            </Switch>
        </View>
    );
}

export default HomeNavigator;
