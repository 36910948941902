import React from 'react';
import { View, StyleSheet, Image, Text, TouchableOpacity, ImageSourcePropType } from 'react-native';
import { useHistory, useLocation } from 'react-router';

import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { useWindowSize } from '../../style/utils';
import { useCustomerDashboardUser } from '../../lib/dashboardUser/dashboardUser';
import { useCustomer } from '../../lib/customer/customer';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };

const megaphoneIconGreen = { uri: '/assets/images/icons/megaphone-green.svg' };
const megaphoneIconGray = { uri: '/assets/images/icons/megaphone-gray.svg' };
const rightLeftArrowsIconGreen = { uri: '/assets/images/icons/right-left-arrows-green.svg' };
const rightLeftArrowsIconGray = { uri: '/assets/images/icons/right-left-arrows-gray.svg' };
const presentationScreenIconGreen = { uri: '/assets/images/icons/presentation-screen-green.svg' };
const presentationScreenIconGray = { uri: '/assets/images/icons/presentation-screen-gray.svg' };

const texts = localeTexts.home.navigationPanel;

function NavigationPanel() {
    const history = useHistory();
    const size = useWindowSize();
    return (
        <View style={[styles.containerNavigationPanel, { height: NAVIGATION_BAR_HEIGHT, width: size.width }]}>
            <View style={styles.containerLeftBlock}>
                <TouchableOpacity onPress={() => history.push('/home')}>
                    <Image source={logo} style={styles.imageLogo} />
                </TouchableOpacity>
            </View>
            <View style={styles.containerCenterBlock}>
                <NavigationButtons />
            </View>
            <View style={styles.containerRightBlock}>
                <ProfileButton />
            </View>
        </View>
    );
}

export default NavigationPanel;

const navigationRoutesConfig: {
    id: string;
    pathname: string;
    icon: {
        default: ImageSourcePropType;
        selected: ImageSourcePropType;
    };
    name: string;
}[] = [
    {
        id: 'campaigns',
        pathname: '/home/campaigns',
        icon: {
            default: megaphoneIconGray,
            selected: megaphoneIconGreen,
        },
        name: texts.routes.campaigns,
    },
    {
        id: 'transactions',
        pathname: '/home/transactions',
        icon: {
            default: rightLeftArrowsIconGray,
            selected: rightLeftArrowsIconGreen,
        },
        name: texts.routes.transactions,
    },
    {
        id: 'insights',
        pathname: '/home/insights',
        icon: {
            default: presentationScreenIconGray,
            selected: presentationScreenIconGreen,
        },
        name: texts.routes.insights,
    },
];

function NavigationButtons() {
    const location = useLocation();
    const history = useHistory();
    const selectedId: string | undefined = React.useMemo(
        () => navigationRoutesConfig.find(({ pathname }) => location.pathname.startsWith(pathname))?.id,
        [location]
    );
    const [customer] = useCustomer();
    if (!customer) return <View style={styles.containerNavigationButtons} />;
    return (
        <View style={styles.containerNavigationButtons}>
            {navigationRoutesConfig.map(({ id, pathname, icon, name }) => (
                <TouchableOpacity key={id} style={styles.containerNavigationButton} onPress={() => history.push(pathname)}>
                    <Image source={selectedId === id ? icon.selected : icon.default} style={styles.imageNavigationButton} />
                    <Text style={[styles.textNavigationButton, selectedId === id && styles.textNavigationButtonSelected]}>
                        {name}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    );
}

function ProfileButton() {
    const history = useHistory();
    const customerDashboardUser = useCustomerDashboardUser();
    const [customer] = useCustomer();
    return (
        <HoverableTouchableOpacity
            style={styles.containerProfileButton}
            hoveredStyle={{ opacity: 0.8 }}
            onPress={() => history.push('/home/profile')}>
            <View style={styles.containerProfileIcon}>
                <Text style={styles.textProfileIcon}>
                    {customerDashboardUser?.firstName?.slice(0, 1) || ''}
                    {customerDashboardUser?.lastName?.slice(0, 1) || ''}
                </Text>
            </View>
            <View style={styles.containerProfileTexts}>
                <Text style={styles.textUserName}>
                    {customerDashboardUser?.firstName || ''} {customerDashboardUser?.lastName || ''}
                </Text>
                <Text style={styles.textCustomerName}>{customer?.customerName || ''}</Text>
            </View>
        </HoverableTouchableOpacity>
    );
}

const styles = StyleSheet.create({
    containerNavigationPanel: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: NAVIGATION_BAR_HEIGHT,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        zIndex: 1,
        backgroundColor: color.athensGray,
    },
    containerLeftBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 30,
        width: 240,
    },
    containerCenterBlock: {
        flexDirection: 'row',
    },
    containerRightBlock: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 60,
        width: 240,
    },
    containerNavigationButtons: {
        flexDirection: 'row',
    },
    containerNavigationButton: {
        width: 180,
        height: NAVIGATION_BAR_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerProfileButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerProfileIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        width: 24,
        borderRadius: 24,
        backgroundColor: color.codGray,
    },
    containerProfileTexts: {
        marginLeft: 16,
        flexDirection: 'column',
    },
    textNavigationButton: {
        fontFamily: font.ambitRegular,
        marginTop: 6,
        fontSize: 14,
        color: color.frenchGray,
    },
    textNavigationButtonSelected: {
        color: color.tundora,
    },
    textProfileIcon: {
        fontFamily: font.ambitRegular,
        fontSize: 12,
        color: color.white,
    },
    textUserName: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    textCustomerName: {
        fontFamily: font.ambitRegular,
        fontSize: 12,
        color: color.osloGray,
    },
    imageLogo: {
        width: 72,
        height: 32,
        resizeMode: 'contain',
    },
    imageNavigationButton: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
});
