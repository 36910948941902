import { Text, TextStyle, TextProps } from 'react-native';
import color from '../../style/color';

export function HighlightedText(props: {
    children: string;
    style?: TextStyle | TextStyle[];
    highlightedStyle?: TextStyle | TextStyle[];
    additionalTextProps?: TextProps;
    onPressHighlightedTexts?: (() => void)[];
}) {
    const { highlightedStyle = { color: color.emerald }, onPressHighlightedTexts } = props;
    return (
        <Text style={props.style} {...props.additionalTextProps}>
            {props.children.split('*').map((text, highlightIndex) =>
                text.split('~~').map((text, strikeThroughIndex) => (
                    <Text
                        key={`highlighted-text-${highlightIndex}-${strikeThroughIndex}`}
                        style={[
                            highlightIndex % 2 !== 0 && highlightedStyle,
                            strikeThroughIndex % 2 !== 0 && { textDecorationLine: 'line-through' },
                        ]}
                        onPress={
                            onPressHighlightedTexts &&
                            highlightIndex % 2 !== 0 &&
                            onPressHighlightedTexts[Math.trunc(highlightIndex / 2)]
                                ? () => onPressHighlightedTexts[Math.trunc(highlightIndex / 2)]()
                                : undefined
                        }>
                        {text}
                    </Text>
                ))
            )}
        </Text>
    );
}

export default HighlightedText;
