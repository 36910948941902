import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { CustomerTransaction } from '../../api/graphql/fragments/transactions';
import {
    customerTransactionsQuery,
    CustomerTransactionsQueryResponse,
    CustomerTransactionsQueryVariables,
} from '../../api/graphql/queries/transactions';

export function useCustomerTransactions({
    startDate,
    endDate,
    customerCampaignId,
}: {
    startDate: string;
    endDate?: string;
    customerCampaignId?: string;
}): [CustomerTransaction[] | undefined, boolean] {
    const queryResponse = useQuery<CustomerTransactionsQueryResponse, CustomerTransactionsQueryVariables>(
        customerTransactionsQuery,
        {
            fetchPolicy: 'cache-and-network',
            variables: { startDate, endDate, customerCampaignId },
        }
    );
    const transactions = React.useMemo(() => queryResponse?.data?.customer?.transactions?.items || undefined, [
        queryResponse,
    ]);
    return [transactions, queryResponse.loading];
}
