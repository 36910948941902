import gql from 'graphql-tag';

export const exportTransactionsToCSVMutation = gql`
    mutation ExportTransactionsToCSV($startDate: String!, $endDate: String) {
        exportTransactionsToCSV(startDate: $startDate, endDate: $endDate)
    }
`;

export interface ExportTransactionsToCSVMutationVariables {
    startDate: string;
    endDate: string;
}

export interface ExportTransactionsToCSVMutationResponse {
    __typename: 'Mutation';
    exportTransactionsToCSV: string;
}
