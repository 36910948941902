import { BrowserRouter as Router, Redirect, Route, useRouteMatch } from 'react-router-dom';

import SignInPage from '../../pages/auth/SignInPage';
import SignUpPage from '../../pages/auth/SignUpPage';

function AuthNavigator() {
    let { path } = useRouteMatch();
    return (
        <Router>
            <Route exact path={path}>
                <Redirect to={`${path}/sign-in`} />
            </Route>
            <Route exact path={`${path}/sign-in`}>
                <SignInPage />
            </Route>
            <Route exact path={`${path}/sign-up`}>
                <SignUpPage />
            </Route>
        </Router>
    );
}

export default AuthNavigator;
