import gql from 'graphql-tag';

export const insightsMerchantFragment = gql`
    fragment InsightsMerchant on InsightsMerchant {
        merchantId
        merchantName
        logoURL
    }
`;

export interface InsightsMerchant {
    __typename: 'InsightsMerchant';
    merchantId: string;
    merchantName: string;
    logoURL: string | null;
}

export const dataIdFromInsightsMerchant = (insightsMerchant?: InsightsMerchant) => {
    return `InsightsMerchant:${insightsMerchant?.merchantId}`;
};
