import gql from 'graphql-tag';

export const customerTransactionFragment = gql`
    fragment CustomerTransaction on CustomerTransaction {
        customerCampaignId
        transactionId
        date
        offerId
        amount
        wording
        potentialCommission
        commissionStatus
        userHashedEmail
    }
`;

export enum CommissionStatus {
    pending = 'pending',
    confirmed = 'confirmed',
    invoiced = 'invoiced',
    paid = 'paid',
    declined = 'declined',
    noCommission = 'no_commission',
}

export interface CustomerTransaction {
    __typename: 'CustomerTransaction';
    customerCampaignId: string;
    transactionId: string;
    date: string;
    offerId: string;
    amount: number;
    wording: string;
    potentialCommission: number;
    commissionStatus: CommissionStatus;
    userHashedEmail: string | null;
}

export const dataIdFromCustomerTransaction = (transaction?: { transactionId: string }) => {
    return `CustomerTransaction:${transaction?.transactionId}`;
};
