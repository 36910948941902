import React from 'react';
import { TouchableWithoutFeedback, View, StyleSheet, Modal } from 'react-native';
import { useHistory } from 'react-router-dom';

import { useWindowSize } from '../../style/utils';

export default function ModalContainer({ children, cannotClose }: { children: JSX.Element; cannotClose?: boolean }) {
    const history = useHistory();
    const size = useWindowSize();
    const [isVisible, setIsVisible] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => setIsVisible(true), 200);
    }, []);
    return (
        <Modal transparent={true}>
            <TouchableWithoutFeedback onPress={() => !cannotClose && history.goBack()}>
                <View
                    style={[
                        styles.containerModalBackground,
                        { height: size.height, width: size.width, opacity: isVisible ? 1 : 0 },
                    ]}>
                    {children}
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

const styles = StyleSheet.create({
    containerModalBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 10,
    },
});
