export const DEV_STACK_MODE_LOCAL_STORAGE_KEY = '@AuthenticationStore:devStackMode';

export function getDevStackMode(): boolean {
    try {
        const item: string | null = window.localStorage.getItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY);
        return item === 'activated';
    } catch {
        return false;
    }
}

export function toggleDevStackMode(): void {
    const devStackMode = getDevStackMode();
    if (devStackMode) window.localStorage.setItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY, 'deactivated');
    else window.localStorage.setItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY, 'activated');
}
