import React from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    Modal,
    LayoutChangeEvent,
    Image,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';
import * as _ from 'lodash';
import moment from 'moment';

import { localeTexts } from '../../Locales';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { useWindowSize } from '../../style/utils';
import color from '../../style/color';
import { font } from '../../style/text';
import { CommissionStatus, CustomerTransaction } from '../../api/graphql/fragments/transactions';
import { confirmTransaction } from '../../api/graphql/mutations/confirmTransaction';
import { declineTransaction } from '../../api/graphql/mutations/declineTransaction';
import { useCustomerTransactions } from '../../lib/transactions/transactions';
import { useCustomer } from '../../lib/customer/customer';
import HighlightedText from '../../components/common/HighlightedText';
import ModalContainer from '../../components/common/ModalContainer';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';
import { DatePicker } from '../../components/common/DatePicker';
import TransactionsExportButton from '../../components/home/TransactionsExportButton';
import TransactionsImportButton from '../../components/home/TransactionsImportButton';

const checkMarkIcon = { uri: '/assets/images/icons/check-mark-green-thin.svg' };
const redCrossIcon = { uri: '/assets/images/icons/cross-red-thin.svg' };
const blackCrossIcon = { uri: '/assets/images/icons/cross-black-thin.svg' };
const emptyImage = { uri: '/assets/images/illustrations/undraw-empty.svg' };

const texts = localeTexts.home.transactions;

export const MAX_DATE = moment().format('YYYY-MM-DD');
export const MIN_DATE = '2021-01-01';
export const DEFAULT_END_DATE = MAX_DATE;
export let DEFAULT_START_DATE = moment(DEFAULT_END_DATE).subtract(7, 'days').format('YYYY-MM-DD');
DEFAULT_START_DATE = DEFAULT_START_DATE >= MIN_DATE ? DEFAULT_START_DATE : MIN_DATE;

export default function TransactionsPage() {
    const size = useWindowSize();
    const [startDate, setStartDate] = React.useState(DEFAULT_START_DATE);
    const [endDate, setEndDate] = React.useState(DEFAULT_END_DATE);
    const [transactions, isLoading] = useCustomerTransactions({ startDate, endDate });
    const sortedTransactions = _.orderBy(transactions, 'date');
    const [customer] = useCustomer();
    return (
        <View style={[styles.container, { height: (size.height || 0) - NAVIGATION_BAR_HEIGHT, width: size.width }]}>
            <View style={styles.containerHeader}>
                <Text style={styles.textTitle}>{texts.title}</Text>
                <View style={styles.containerHeaderButtons}>
                    <TransactionsExportButton initialStartDate={startDate} initialEndDate={endDate} />
                    {customer?.needsToApproveTransactions ? <TransactionsImportButton /> : null}
                </View>
            </View>
            <View style={styles.containerFilters}>
                <DatePicker
                    {...{ startDate, setStartDate, endDate, setEndDate }}
                    maxDate={MAX_DATE}
                    minDate={MIN_DATE}
                    minDateRangeInDays={1}
                />
            </View>
            <View style={{ maxHeight: (size.height || 0) - 175 }}>
                {transactions === undefined || (!transactions.length && isLoading) ? (
                    <ActivityIndicator size={'large'} color={color.frenchGray} />
                ) : !transactions.length ? (
                    <View style={styles.containerEmptyTransactionList}>
                        <Image source={emptyImage} style={{ width: 200, height: 200, resizeMode: 'contain' }} />
                        <Text style={styles.textEmptyTransactionListTitle}>{texts.emptyTransactionList.title}</Text>
                        <Text style={styles.textEmptyTransactionListSubtitle}>{texts.emptyTransactionList.subtitle}</Text>
                    </View>
                ) : (
                    <ProgressiveFlatList
                        style={styles.containerTransactionTable}
                        data={sortedTransactions}
                        renderItem={({ item, index }) => (
                            <TransactionTableRow
                                transaction={item}
                                index={index}
                                customerNeedsToApproveTransactions={customer?.needsToApproveTransactions || false}
                            />
                        )}
                        keyExtractor={(item) => item.transactionId}
                        ListHeaderComponent={<TransactionTableHeader />}
                        stickyHeaderIndices={[0]}
                        initialNumberOfItemsToDisplay={10}
                    />
                )}
            </View>
        </View>
    );
}

function TransactionTableHeader() {
    return (
        <View style={styles.containerTransactionTableHeader}>
            <View style={styles.containerTransactionTableCellWording}>
                <Text style={styles.textTransactionTableHeader}>{texts.transactionTable.header.wording.toUpperCase()}</Text>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTableHeader}>{texts.transactionTable.header.amount.toUpperCase()}</Text>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTableHeader}>
                    {texts.transactionTable.header.commission.toUpperCase()}
                </Text>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTableHeader}>{texts.transactionTable.header.date.toUpperCase()}</Text>
            </View>
            <View style={styles.containerTransactionTableCellStatus}>
                <Text style={styles.textTransactionTableHeader}>{texts.transactionTable.header.status.toUpperCase()}</Text>
            </View>
        </View>
    );
}

function TransactionTableRow({
    transaction,
    index,
    customerNeedsToApproveTransactions,
}: {
    transaction: CustomerTransaction;
    index: number;
    customerNeedsToApproveTransactions: boolean;
}) {
    const { wording, transactionId, userHashedEmail, amount, potentialCommission, date, commissionStatus } = transaction;
    return (
        <View style={[styles.containerTransactionTableRow, index % 2 === 1 && { backgroundColor: color.athensGrayLight }]}>
            <View style={styles.containerTransactionTableCellWording}>
                <Text style={styles.textTransactionTablePrimary}>{wording}</Text>
                <HighlightedText
                    style={styles.textTransactionTableSecondary}
                    highlightedStyle={styles.textTransactionTableId}
                    additionalTextProps={{ numberOfLines: 1 }}>
                    {texts.transactionTable.transactionId({ transactionId })}
                </HighlightedText>
                <HighlightedText
                    style={styles.textTransactionTableSecondary}
                    highlightedStyle={styles.textTransactionTableId}
                    additionalTextProps={{ numberOfLines: 1 }}>
                    {texts.transactionTable.userHashedEmail({ userHashedEmail })}
                </HighlightedText>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTablePrimary}>{texts.transactionTable.amount({ amount })}</Text>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTablePrimary}>
                    {texts.transactionTable.commission({ commission: potentialCommission })}
                </Text>
            </View>
            <View style={styles.containerTransactionTableCellDefault}>
                <Text style={styles.textTransactionTablePrimary}>{texts.transactionTable.date({ date })}</Text>
            </View>
            <View style={styles.containerTransactionTableCellStatus}>
                <TransactionStatus {...{ transaction, commissionStatus, customerNeedsToApproveTransactions }} />
            </View>
        </View>
    );
}

function TransactionStatus({
    transaction,
    commissionStatus,
    customerNeedsToApproveTransactions,
}: {
    transaction: CustomerTransaction;
    commissionStatus: CommissionStatus;
    customerNeedsToApproveTransactions: boolean;
}) {
    const [isDropDownMenuVisible, setIsDropDownMenuVisible] = React.useState(false);
    const [isDeclineTransactionModalVisible, setIsDeclineTransactionModalVisible] = React.useState(false);
    const [leftPosition, setLeftPosition] = React.useState<number | undefined>(undefined);
    const [topPosition, setTopPosition] = React.useState<number | undefined>(undefined);
    const [reRenderTimestamp, setReRenderTimestamp] = React.useState<number | undefined>(undefined);
    const onLayout = (event: LayoutChangeEvent) => {
        setLeftPosition((event?.nativeEvent?.layout as any).left);
        setTopPosition((event?.nativeEvent?.layout as any).top);
    };
    if (
        !customerNeedsToApproveTransactions &&
        [CommissionStatus.pending, CommissionStatus.confirmed, CommissionStatus.declined].includes(commissionStatus)
    )
        return null;
    if (!isDropDownMenuVisible)
        return (
            <>
                <TransactionStatusLabel
                    key={`transaction-status-label-${transaction.transactionId}-${reRenderTimestamp}`}
                    {...{ onLayout }}
                    commissionStatus={commissionStatus}
                    onPress={
                        commissionStatus === CommissionStatus.pending
                            ? () => {
                                  setReRenderTimestamp(moment().unix()); // We re-render the TransactionStatusLabel component to update its position
                                  setTimeout(() => setIsDropDownMenuVisible(true), 50); // We wait for the position to be updated before showing the menu
                              }
                            : undefined
                    }
                />
                {isDeclineTransactionModalVisible ? (
                    <DeclineTransactionModal
                        {...{ transaction }}
                        onClose={() => setIsDeclineTransactionModalVisible(false)}
                    />
                ) : null}
            </>
        );
    return (
        <TransactionStatusDropDownMenu
            {...{
                transaction,
                commissionStatus,
                leftPosition,
                topPosition,
                setIsDeclineTransactionModalVisible,
            }}
            onClose={() => setIsDropDownMenuVisible(false)}
        />
    );
}

function TransactionStatusLabel({
    commissionStatus,
    onPress,
    onLayout,
}: {
    commissionStatus: CommissionStatus;
    onPress?: () => void;
    onLayout?: (event: LayoutChangeEvent) => void;
}) {
    const getContainerStyle = (commissionStatus: CommissionStatus) => [
        styles.containerTransactionStatusLabel,
        commissionStatus === CommissionStatus.confirmed && { backgroundColor: color.whiteIce },
        commissionStatus === CommissionStatus.pending && { backgroundColor: color.scotchMist },
        commissionStatus === CommissionStatus.declined && { backgroundColor: color.fairPink },
    ];
    const getTextStyle = (commissionStatus: CommissionStatus) => [
        styles.textTransactionStatusLabel,
        commissionStatus === CommissionStatus.confirmed && { color: color.emerald },
        commissionStatus === CommissionStatus.pending && { color: color.treePoppy },
        commissionStatus === CommissionStatus.declined && { color: color.radicalRed },
    ];
    return (
        <TouchableOpacity disabled={!onPress} {...{ onPress, onLayout }} style={getContainerStyle(commissionStatus)}>
            <Text style={getTextStyle(commissionStatus)}>{texts.transactionTable.status({ status: commissionStatus })}</Text>
        </TouchableOpacity>
    );
}

function TransactionStatusDropDownMenu({
    transaction,
    commissionStatus,
    leftPosition,
    topPosition,
    setIsDeclineTransactionModalVisible,
    onClose,
}: {
    transaction: CustomerTransaction;
    commissionStatus: CommissionStatus;
    leftPosition: number | undefined;
    topPosition: number | undefined;
    setIsDeclineTransactionModalVisible: (value: boolean) => void;
    onClose: () => void;
}) {
    const windowSize = useWindowSize();
    const apolloClient = useApolloClient();
    return (
        <Modal transparent={true}>
            <TouchableOpacity
                onPress={onClose}
                style={{ height: windowSize.height, width: windowSize.width }}
                activeOpacity={1}>
                <View
                    style={[
                        styles.containerTransactionStatusDropDownMenu,
                        {
                            left: (leftPosition || 0) - DROP_DOWN_MENU_HORIZONTAL_PADDING,
                            top: (topPosition || 0) - DROP_DOWN_MENU_VERTICAL_PADDING,
                        },
                    ]}>
                    <TransactionStatusLabel {...{ commissionStatus }} />
                    <TouchableOpacity
                        style={styles.containerTransactionStatusDropDownOption}
                        onPress={() => {
                            confirmTransaction(apolloClient, transaction.transactionId);
                            onClose();
                        }}>
                        <Image
                            source={checkMarkIcon}
                            style={{ width: 11.5, height: 8.5, resizeMode: 'contain', marginRight: 6 }}
                        />
                        <Text style={styles.textTransactionStatusDropDownOption}>
                            {texts.transactionStatusDropDownMenu.confirm}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.containerTransactionStatusDropDownOption}
                        onPress={() => {
                            setIsDeclineTransactionModalVisible(true);
                            onClose();
                        }}>
                        <Image
                            source={redCrossIcon}
                            style={{ width: 8.5, height: 8.5, resizeMode: 'contain', marginRight: 9 }}
                        />
                        <Text style={styles.textTransactionStatusDropDownOption}>
                            {texts.transactionStatusDropDownMenu.decline}
                        </Text>
                    </TouchableOpacity>
                </View>
            </TouchableOpacity>
        </Modal>
    );
}

function DeclineTransactionModal({ transaction, onClose }: { transaction: CustomerTransaction; onClose: () => void }) {
    const apolloClient = useApolloClient();
    const [reason, setReason] = React.useState('');
    const { wording, transactionId, userHashedEmail, amount, date } = transaction;
    return (
        <ModalContainer cannotClose>
            <View style={styles.containerDeclineTransactionModal}>
                <View style={styles.containerDeclineTransactionModalHeader}>
                    <Text style={styles.textDeclineTransactionModalTitle}>{texts.declineTransactionModal.title}</Text>
                    <TouchableOpacity onPress={onClose}>
                        <Image source={blackCrossIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    </TouchableOpacity>
                </View>
                <View style={styles.containerDeclineTransactionModalContent}>
                    <Text style={styles.textTransactionTablePrimary}>{wording}</Text>
                    <HighlightedText
                        style={styles.textTransactionTableSecondary}
                        highlightedStyle={styles.textTransactionTableId}
                        additionalTextProps={{ numberOfLines: 1 }}>
                        {texts.declineTransactionModal.transactionId({ transactionId })}
                    </HighlightedText>
                    <HighlightedText
                        style={styles.textTransactionTableSecondary}
                        highlightedStyle={styles.textTransactionTableId}
                        additionalTextProps={{ numberOfLines: 1 }}>
                        {texts.declineTransactionModal.userHashedEmail({ userHashedEmail })}
                    </HighlightedText>
                    <HighlightedText
                        style={styles.textTransactionTableSecondary}
                        highlightedStyle={styles.textTransactionTableId}
                        additionalTextProps={{ numberOfLines: 1 }}>
                        {texts.declineTransactionModal.amount({ amount })}
                    </HighlightedText>
                    <HighlightedText
                        style={styles.textTransactionTableSecondary}
                        highlightedStyle={styles.textTransactionTableId}
                        additionalTextProps={{ numberOfLines: 1 }}>
                        {texts.declineTransactionModal.date({ date })}
                    </HighlightedText>
                    <View style={styles.containerDeclineTransactionModalReason}>
                        <TextInput
                            style={styles.textDeclineTransactionModalReason}
                            placeholder={texts.declineTransactionModal.reason}
                            value={reason}
                            keyboardType={'default'}
                            returnKeyType="next"
                            autoFocus={true}
                            underlineColorAndroid="transparent"
                            onChangeText={setReason}
                            placeholderTextColor={color.frenchGray}
                        />
                    </View>
                </View>
                <View style={styles.containerDeclineTransactionModalButtons}>
                    <DeclineTransactionModalButton
                        onPress={onClose}
                        isSecondary
                        text={texts.declineTransactionModal.cancelButton}
                    />
                    <View style={{ width: 8 }} />
                    <DeclineTransactionModalButton
                        onPress={() => {
                            declineTransaction(apolloClient, transaction.transactionId, reason);
                            onClose();
                        }}
                        disabled={reason.length === 0}
                        text={texts.declineTransactionModal.declineButton}
                    />
                </View>
            </View>
        </ModalContainer>
    );
}

function DeclineTransactionModalButton({
    onPress,
    disabled,
    isSecondary,
    text,
}: {
    onPress: () => void;
    disabled?: boolean;
    isSecondary?: boolean;
    text: string;
}) {
    return (
        <TouchableOpacity
            {...{ onPress, disabled }}
            style={
                !disabled && !isSecondary
                    ? styles.containerDeclineTransactionModalPrimaryButton
                    : styles.containerDeclineTransactionModalSecondaryButton
            }>
            <Text
                style={
                    !disabled && !isSecondary
                        ? styles.textDeclineTransactionModalPrimaryButton
                        : styles.textDeclineTransactionModalSecondaryButton
                }>
                {text}
            </Text>
        </TouchableOpacity>
    );
}

const DROP_DOWN_MENU_HORIZONTAL_PADDING = 15;
const DROP_DOWN_MENU_VERTICAL_PADDING = 13;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 48,
    },
    containerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 50,
        paddingBottom: 24,
    },
    containerHeaderButtons: {
        flexDirection: 'row',
    },
    containerFilters: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 27,
    },
    containerEmptyTransactionList: {
        marginTop: 175,
        alignSelf: 'center',
        alignItems: 'center',
        width: 340,
    },
    containerTransactionTable: {
        borderWidth: 1,
        borderColor: color.athensGray,
        borderRadius: 8,
        marginBottom: 50,
    },
    containerTransactionTableHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        paddingHorizontal: 25,
        backgroundColor: color.athensGray,
    },
    containerTransactionTableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 78,
        paddingHorizontal: 25,
        backgroundColor: color.white,
    },
    containerTransactionTableCellDefault: {
        marginLeft: 24,
        width: '12%',
    },
    containerTransactionTableCellWording: {
        minWidth: '20%',
        flex: 1,
    },
    containerTransactionTableCellStatus: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '17%',
    },
    containerTransactionStatusLabel: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        paddingHorizontal: 12,
        borderRadius: 40,
        backgroundColor: color.linkWaterLight,
    },
    containerTransactionStatusDropDownMenu: {
        position: 'absolute',
        paddingVertical: DROP_DOWN_MENU_VERTICAL_PADDING,
        paddingHorizontal: DROP_DOWN_MENU_HORIZONTAL_PADDING,
        backgroundColor: color.white,
        borderRadius: 15,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.06,
        shadowRadius: 12,
    },
    containerTransactionStatusDropDownOption: {
        marginLeft: 5,
        marginTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDeclineTransactionModal: {
        width: 520,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerDeclineTransactionModalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 64,
        paddingHorizontal: 24,
        borderBottomWidth: 1,
        borderBottomColor: color.athensGrayDark,
    },
    containerDeclineTransactionModalContent: {
        padding: 24,
    },
    containerDeclineTransactionModalReason: {
        marginTop: 24,
        justifyContent: 'center',
        height: 36,
        paddingHorizontal: 8,
        borderRadius: 8,
        backgroundColor: color.athensGray,
    },
    containerDeclineTransactionModalButtons: {
        alignSelf: 'flex-end',
        flexDirection: 'row',
        marginRight: 24,
        marginBottom: 24,
    },
    containerDeclineTransactionModalPrimaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.radicalRed,
        borderRadius: 48,
    },
    containerDeclineTransactionModalSecondaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.athensGray,
        borderRadius: 48,
    },
    textTitle: {
        fontFamily: font.ambitRegular,
        fontSize: 24,
        color: color.tundora,
    },
    textEmptyTransactionListTitle: {
        marginTop: 35,
        fontFamily: font.ambitRegular,
        fontSize: 20,
        lineHeight: 32,
        color: color.tundora,
    },
    textEmptyTransactionListSubtitle: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        lineHeight: 20,
        color: color.osloGray,
        textAlign: 'center',
    },
    textTransactionTableHeader: {
        fontFamily: font.ambitSemiBold,
        fontSize: 12,
        color: color.osloGray,
    },
    textTransactionTablePrimary: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        lineHeight: 20,
        color: color.tundora,
    },
    textTransactionTableSecondary: {
        fontFamily: font.ambitSemiBold,
        fontSize: 12,
        lineHeight: 18,
        color: color.frenchGray,
    },
    textTransactionTableId: {
        fontFamily: 'Courier New',
        fontSize: 12,
        lineHeight: 18,
        fontWeight: '600',
        color: color.frenchGray,
    },
    textTransactionStatusLabel: {
        fontFamily: font.ambitRegular,
        fontSize: 12,
        color: color.codGray,
    },
    textTransactionStatusDropDownOption: {
        fontFamily: font.ambitRegular,
        fontSize: 12,
        color: color.osloGray,
    },
    textDeclineTransactionModalTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.tundora,
    },
    textDeclineTransactionModalReason: {
        fontFamily: font.ambitRegular,
        color: color.tundora,
        fontSize: 14,
        outlineWidth: 0,
    },
    textDeclineTransactionModalPrimaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
    textDeclineTransactionModalSecondaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
});
