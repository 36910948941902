import gql from 'graphql-tag';

export const customerCampaignStatisticsFragment = gql`
    fragment CustomerCampaignStatistics on CustomerCampaignStatistics {
        customerCampaignId
        offerId
        targetingType
        numberOfTransactions
        totalTransactionAmount
        totalCommission
    }
`;

export const customerCampaignFragment = gql`
    fragment CustomerCampaign on CustomerCampaign {
        customerCampaignId
        offerIds
        startDate
        endDate
        statistics {
            ...CustomerCampaignStatistics
        }
        campaignType # Deprecated, replaced by targetingType in statistics
    }
    ${customerCampaignStatisticsFragment}
`;

export enum TargetingType {
    acquisition = 'acquisition',
    reactivation = 'reactivation',
    loyalty = 'loyalty',
    allUsers = 'all_users',
    other = 'other',
}
export interface CustomerCampaignStatistics {
    __typename: 'CustomerCampaignStatistics';
    customerCampaignId: string;
    offerId: string;
    targetingType: TargetingType | null;
    numberOfTransactions: number;
    totalTransactionAmount: number;
    totalCommission: number;
}

export interface CustomerCampaign {
    __typename: 'CustomerCampaign';
    customerCampaignId: string;
    offerIds: string[];
    startDate: string;
    endDate: string | null;
    statistics: CustomerCampaignStatistics[];
    campaignType: TargetingType | null;
}

export const dataIdFromCustomerCampaign = (customerCampaign?: CustomerCampaign) => {
    return `CustomerCampaign:${customerCampaign?.customerCampaignId}`;
};

export const dataIdFromCustomerCampaignStatistics = (statistics?: CustomerCampaignStatistics) => {
    return `CustomerCampaignStatistics:${statistics?.customerCampaignId}|${statistics?.offerId}`;
};
