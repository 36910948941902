import * as React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Image, ActivityIndicator } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';

import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import {
    exportTransactionsToCSVMutation,
    ExportTransactionsToCSVMutationResponse,
    ExportTransactionsToCSVMutationVariables,
} from '../../api/graphql/mutations/exportTransactionsToCSV';
import ModalContainer from '../common/ModalContainer';
import { DatePicker } from '../common/DatePicker';
import { MAX_DATE, MIN_DATE } from '../../pages/home/TransactionsPage';

const texts = localeTexts.home.transactions;

const crossIcon = { uri: '/assets/images/icons/cross-black-thin.svg' };

export default function TransactionsExportButton({
    initialStartDate,
    initialEndDate,
}: {
    initialStartDate: string;
    initialEndDate: string;
}) {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    return (
        <TouchableOpacity style={styles.containerButton} onPress={() => setIsModalVisible(true)}>
            <Text style={styles.textButton}>{texts.exportButton}</Text>
            {isModalVisible ? (
                <TransactionsExportModal
                    {...{ initialStartDate, initialEndDate }}
                    onClose={() => setIsModalVisible(false)}
                />
            ) : null}
        </TouchableOpacity>
    );
}

function TransactionsExportModal({
    initialStartDate,
    initialEndDate,
    onClose,
}: {
    initialStartDate: string;
    initialEndDate: string;
    onClose: () => void;
}) {
    const apolloClient = useApolloClient();
    const [startDate, setStartDate] = React.useState(initialStartDate);
    const [endDate, setEndDate] = React.useState(initialEndDate);
    const [isLoading, setIsLoading] = React.useState(false);
    const onPressExport = async () => {
        setIsLoading(true);
        const mutationResponse = await apolloClient.mutate<
            ExportTransactionsToCSVMutationResponse,
            ExportTransactionsToCSVMutationVariables
        >({ mutation: exportTransactionsToCSVMutation, variables: { startDate, endDate } });
        if (mutationResponse.data?.exportTransactionsToCSV)
            window.location.assign(mutationResponse.data.exportTransactionsToCSV);
        setIsLoading(false);
        onClose();
    };
    return (
        <ModalContainer cannotClose>
            <View style={styles.containerModal}>
                <View style={styles.containerModalHeader}>
                    <Text style={styles.textModalTitle}>{texts.exportModal.title}</Text>
                    <TouchableOpacity onPress={onClose}>
                        <Image source={crossIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    </TouchableOpacity>
                </View>
                <View style={styles.containerModalContent}>
                    <Text style={styles.textModalContent}>{texts.exportModal.dateRange}</Text>
                    <DatePicker
                        {...{ startDate, setStartDate, endDate, setEndDate }}
                        maxDate={MAX_DATE}
                        minDate={MIN_DATE}
                        minDateRangeInDays={1}
                    />
                </View>
                <View style={styles.containerModalButtons}>
                    <ModalButton onPress={onClose} isSecondary text={texts.exportModal.cancelButton} />
                    <View style={{ width: 8 }} />
                    <ModalButton onPress={onPressExport} text={texts.exportModal.exportButton} isLoading={isLoading} />
                </View>
            </View>
        </ModalContainer>
    );
}

function ModalButton({
    onPress,
    isSecondary,
    text,
    isLoading,
}: {
    onPress: () => void;
    isSecondary?: boolean;
    text: string;
    isLoading?: boolean;
}) {
    return (
        <TouchableOpacity
            {...{ onPress }}
            disabled={isLoading}
            style={!isSecondary ? styles.containerModalPrimaryButton : styles.containerModalSecondaryButton}>
            {isLoading ? (
                <ActivityIndicator color={color.white} size={'small'} />
            ) : (
                <Text style={!isSecondary ? styles.textModalPrimaryButton : styles.textModalSecondaryButton}>{text}</Text>
            )}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    containerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        paddingHorizontal: 16,
        borderRadius: 48,
        backgroundColor: color.athensGray,
    },
    textButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    containerModal: {
        width: 450,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerModalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 64,
        paddingHorizontal: 24,
        borderBottomWidth: 1,
        borderBottomColor: color.athensGrayDark,
    },
    containerModalContent: {
        padding: 24,
    },
    containerModalButtons: {
        alignSelf: 'flex-end',
        flexDirection: 'row',
        marginRight: 24,
        marginBottom: 24,
    },
    containerModalPrimaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.emerald,
        borderRadius: 48,
    },
    containerModalSecondaryButton: {
        justifyContent: 'center',
        height: 32,
        paddingHorizontal: 16,
        backgroundColor: color.athensGray,
        borderRadius: 48,
    },
    textModalTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.tundora,
    },
    textModalContent: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
        marginBottom: 4,
    },
    textModalPrimaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
    textModalSecondaryButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
});
