import 'moment/locale/fr';
import moment from 'moment';

import { LocaleTexts } from '../Locales';
import { TargetingType } from '../api/graphql/fragments/campaigns';
import { CommissionStatus } from '../api/graphql/fragments/transactions';

export const frTexts: LocaleTexts = {
    auth: {
        signIn: {
            title: `Connectez-vous à votre compte`,
            email: `Adresse email`,
            password: `Mot de passe`,
            resetPassword: `Mot de passe oublié ?`,
            button: `Se connecter`,
            error: {
                default: `Il y a eu une erreur, veuillez réessayer plus tard.`,
                wrongCredentials: `Identifiants incorrects`,
            },
            signUp: {
                text: `Pas encore de compte ?`,
                button: `S'inscrire`,
            },
        },
        signUp: {
            panel: {
                paragraph1: {
                    title: 'Une importante base utilisateurs',
                    message: "Plus de 2 millions d'utilisateurs",
                },
                paragraph2: {
                    title: 'Un ciblage précis basé sur la donnée de paiement',
                    message: "Plus de 10 milliards d'euros de transactions analysées",
                },
                paragraph3: {
                    title: 'Un modèle 100% à la performance',
                    message: 'Les partenaires ne payent une commission que sur le chiffre d’affaires additionnel réalisé',
                },
            },
            title: `Créer votre compte marchand`,
            firstNamePlaceholder: 'Prénom',
            lastNamePlaceholder: 'Nom',
            emailPlaceholder: 'Adresse email',
            passwordPlaceholder: 'Mot de passe',
            passwordConfirmationPlaceholder: 'Confirmer mot de passe',
            passwordRequirements: {
                minCharacters: ({ minCharacters }) => `Le mot de passe doit au moins avoir *${minCharacters} caractères*. `,
                minLowerCase: ({ minLowerCase }) => `Avec *${minLowerCase} minuscule${minLowerCase > 1 ? 's' : ''}*, `,
                minUpperCase: ({ minUpperCase }) => `*${minUpperCase} majuscule${minUpperCase > 1 ? 's' : ''}* et `,
                minNumbers: ({ minNumbers }) => `*${minNumbers} chiffre${minNumbers > 1 ? 's' : ''}*.`,
            },
            passwordConfirmationError: `Ce mot de passe ne correspond pas`,
            termsOfService: `J'accepte les *conditions générales* et la *politique de confidentialité*`,
            signUpButton: 'Créer un compte',
            signIn: {
                text: `Vous avez un compte ?`,
                button: `Connexion`,
            },
            error: {
                default: `Il y a eu une erreur, veuillez réessayer plus tard`,
            },
            emailVerification: {
                title: `Vérifiez votre adresse email`,
                subtitle: ({ email }) =>
                    `Un email vous a été envoyé à *${email}*, merci de cliquer sur le lien pour valider votre compte.`,
                loading: 'Connexion en cours',
            },
        },
    },
    home: {
        navigationPanel: {
            routes: {
                campaigns: 'Mes campagnes',
                transactions: 'Transactions',
                insights: 'Joko Insights',
            },
        },
        profile: {
            navigationBar: {
                title: 'Profil',
                signOutButton: 'Se déconnecter',
            },
            userInfo: {
                credentialsSection: {
                    title: 'Connexion',
                    email: 'Email',
                    password: 'Mot de passe',
                    passwordPlaceholder: '••••••',
                },
                identitySection: {
                    title: 'Identité',
                    lastName: 'Nom',
                    firstName: 'Prénom',
                },
            },
        },
        campaigns: {
            title: 'Campagnes',
            helpModal: {
                title: `Qu’est-ce qu’une campagne ?`,
                message:
                    `Les campagnes Joko vous permettent d’acquérir de nouveaux clients, de réactiver les clients ` +
                    `dormants ou de fidéliser les clients existants. Veuillez contacter votre Account Manager pour ` +
                    `lancer de nouvelles campagnes.`,
                button: `Fermer`,
            },
            emptyCampaignList: {
                title: `Aucune campagne`,
                subtitle: `Veuillez contacter votre responsable de compte Joko pour créer de nouvelles campagnes`,
            },
            campaignOfferSummary: {
                targetingType: ({ targetingType }) =>
                    targetingType === TargetingType.acquisition
                        ? 'Acquisition'
                        : targetingType === TargetingType.loyalty
                        ? 'Fidélisation'
                        : targetingType === TargetingType.reactivation
                        ? 'Réactivation'
                        : targetingType === TargetingType.allUsers
                        ? 'Toute audience'
                        : targetingType === TargetingType.other
                        ? 'Personnalisé'
                        : '',
                status: ({ status }) =>
                    status === 'active'
                        ? 'En cours'
                        : status === 'ended'
                        ? 'Terminée'
                        : status === 'notStarted'
                        ? 'En attente'
                        : '',
                date: ({ startDate, endDate }) =>
                    endDate
                        ? `Du ${moment(startDate).format('D MMM')} au ${moment(endDate).format('D MMM YYYY')}`
                        : `Depuis le ${moment(startDate).format('D MMM YYYY')}`,
                statistics: {
                    numberOfTransactions: {
                        label: `Transactions`,
                        value: ({ numberOfTransactions }) => `${formatNumber(numberOfTransactions.toFixed(0))}`,
                    },
                    averageTransactionAmount: {
                        label: `Panier moyen`,
                        value: ({ averageTransactionAmount }) =>
                            averageTransactionAmount ? `${formatNumber(averageTransactionAmount.toFixed(1))} €` : `NA`,
                    },
                    totalTransactionAmount: {
                        label: `Chiffre d'affaires`,
                        value: ({ totalTransactionAmount }) => `${formatNumber(totalTransactionAmount.toFixed(1))} €`,
                    },
                    returnOnInvestment: {
                        label: `ROAS`,
                        value: ({ returnOnInvestment }) =>
                            returnOnInvestment ? `${formatNumber((returnOnInvestment * 100).toFixed(0))} %` : `NA`,
                    },
                },
            },
        },
        transactions: {
            title: 'Transactions',
            emptyTransactionList: {
                title: `Il n'y a rien ici`,
                subtitle: `Aucune transaction trouvée`,
            },
            transactionTable: {
                header: {
                    wording: 'Transactions',
                    amount: 'Montant',
                    commission: 'Commission',
                    date: 'Date',
                    status: 'Statut',
                },
                transactionId: ({ transactionId }) => `Transaction : *${transactionId}*`,
                userHashedEmail: ({ userHashedEmail }) => (userHashedEmail ? `Email (SHA256) : *${userHashedEmail}*` : ``),
                amount: ({ amount }) => `${formatNumber(amount.toFixed(1))}€`,
                commission: ({ commission }) => `${formatNumber(commission.toFixed(1))}€`,
                date: ({ date }) => `${moment(date).format('DD/MM/YYYY')}`,
                status: ({ status }) =>
                    status === CommissionStatus.pending
                        ? `À confirmer`
                        : status === CommissionStatus.confirmed
                        ? `Confirmée`
                        : status === CommissionStatus.declined
                        ? `Refusée`
                        : status === CommissionStatus.invoiced
                        ? `Facturée`
                        : status === CommissionStatus.paid
                        ? `Payée`
                        : ``,
            },
            transactionStatusDropDownMenu: {
                confirm: 'Confirmer',
                decline: 'Refuser',
            },
            declineTransactionModal: {
                title: `Refuser la transaction`,
                reason: `Raison du refus`,
                transactionId: ({ transactionId }) => `Transaction : *${transactionId}*`,
                userHashedEmail: ({ userHashedEmail }) => (userHashedEmail ? `Email (SHA256) : *${userHashedEmail}*` : ``),
                amount: ({ amount }) => `Montant : *${formatNumber(amount.toFixed(2))}€*`,
                date: ({ date }) => `Date : *${moment(date).format('DD/MM/YYYY')}*`,
                cancelButton: `Annuler`,
                declineButton: `Refuser la transaction`,
            },
            exportButton: `Exporter les transactions`,
            exportModal: {
                title: `Exporter les transactions au format CSV`,
                dateRange: `Période`,
                cancelButton: `Annuler`,
                exportButton: `Exporter`,
            },
            importButton: `Valider les transactions`,
            importModal: {
                title: `Importer et valider les transactions`,
                indications:
                    `Vous pouvez importer la liste des transactions que vous souhaitez approuver ou refuser ci-dessous.\n\n` +
                    `Cette liste doit être au format CSV et être du même format que le fichier obtenu en utiliser la fonctionnalité *Exporter les transactions*.\n\n` +
                    `En particulier, les valeurs doivent être séparées par des *points-virgules*, et le fichier doit contenir les colonnes suivantes : ` +
                    `*Identifiant de transaction*, *Marquer X si approuvée*, *Marquer X si refusée*, et *Raison du refus*.`,
                fileDropZone: {
                    button: `Ajouter un fichier`,
                    text: `ou déposer le fichier à mettre en ligne`,
                },
                confirmation: {
                    message: `Êtes-vous certain(e) de vouloir approuver ou refuser les transactions du fichier choisi ?`,
                    warning: `Attention cette action est irréversible`,
                },
                result: {
                    numberOfConfirmedTransactions: `Nombre de transactions approuvées`,
                    numberOfDeclinedTransactions: `Nombre de transactions refusées`,
                    numberOfUnhandledTransactions: `Nombre de transactions qui n'ont pas été mises à jour`,
                    error: ({ error }) =>
                        error.error === 'MissingFile'
                            ? `Le fichier n'a pas pu être importé`
                            : error.error === 'IllFormedFile'
                            ? `Le fichier n'est pas au bon format (CSV utilisant des points-virgules)`
                            : error.error === 'MissingField'
                            ? `Le fichier n'a pas tous les champs nécessaires`
                            : error.error === 'IllFormedLine'
                            ? `La ligne ${error.lineIndex + 1} du fichier n'a pas pu être lue correctement`
                            : error.error === 'TransactionNotFound'
                            ? `La transaction ${error.transactionId} n'a pas pu être trouvée`
                            : error.error === 'TransactionNotPending'
                            ? `La transaction ${error.transactionId} a déjà été approuvée ou refusée auparavant`
                            : error.error === 'MissingDeclinedReason'
                            ? `Il manque la raison du refus pour la transaction ${error.transactionId}`
                            : ``,
                },
                cancelButton: `Annuler`,
                nextButton: `Continuer`,
                closeButton: `Fermer`,
            },
        },
        insights: {
            allMerchants: {
                title: 'Tous les marchands',
                selectMerchantsButton: ({ numberOfSelectedMerchants }) =>
                    numberOfSelectedMerchants < 2
                        ? `Analyser les marchands sélectionnés`
                        : `Analyser les *${numberOfSelectedMerchants}* marchands sélectionnés`,
                merchantTable: {
                    header: {
                        merchantName: `Marchand`,
                        averageTransactionAmount: `Panier moyen`,
                        averageTransactionFrequencyPerUser: `Fréquence moyenne d'achat`,
                        averageTransactionAmountPerUser: `Valeur client`,
                        totalTransactionAmountBaselineRatio: `Montant total dépensé (base 100)`,
                    },
                    averageTransactionAmount: ({ averageTransactionAmount }) =>
                        `${formatNumber(averageTransactionAmount.toFixed(1))}€`,
                    averageTransactionFrequencyPerUser: ({ averageTransactionFrequencyPerUser }) =>
                        `${formatNumber(averageTransactionFrequencyPerUser.toFixed(1))}x`,
                    averageTransactionAmountPerUser: ({ averageTransactionAmountPerUser }) =>
                        `${formatNumber(averageTransactionAmountPerUser.toFixed(1))}€`,
                    totalTransactionAmountBaselineRatio: ({ totalTransactionAmountBaselineRatio }) =>
                        `${formatNumber((totalTransactionAmountBaselineRatio * 100).toFixed(1))}`,
                },
            },
            selectedMerchants: {
                title: 'Analyse des marchands sélectionnés',
                charts: {
                    averageTransactionAmountLineChart: {
                        title: `Évolution des paniers moyens`,
                        yValue: ({ value }) => `${formatNumber(value.toFixed(1))} €`,
                    },
                    totalTransactionAmountBaselineRatioLineChart: {
                        title: `Évolution des ventes (base 100)`,
                        yValue: ({ value }) => `${formatNumber((value * 100).toFixed(0))}`,
                    },
                    marketShareEvolutionBarChart: {
                        title: `Évolution des parts de marché`,
                        yValue: ({ value }) => `${formatNumber((value * 100).toFixed(0))}`,
                        label: ({ value }) => `${formatNumber((value * 100).toFixed(0))}%`,
                    },
                    timeAxis: {
                        week: ({ week }) => moment(week, 'YYYY-[W]ww').format('D MMM'),
                        month: ({ month }) => moment(month, 'YYYY-MM').format('MMM'),
                    },
                },
            },
        },
        metabaseInsights: {
            title: 'Insights sur le marché',
        },
        metabasePerformance: {
            title: 'Performance de mes offres',
        },
        noCustomer: {
            title: `Aucun marchand connecté au compte`,
            subtitle:
                `Merci de vous être inscrit(e). Si vous avez lancé une campagne sur Joko, prévenez votre ` +
                `Account Manager par e-mail, il vous donnera accès au dashboard dans les plus brefs délais.`,
        },
        datePicker: {
            date: ({ startDate, endDate }) =>
                `${moment(startDate).format('D MMM YYYY')} - ${moment(endDate).format('D MMM YYYY')}`,
            confirmButton: 'Confirmer',
        },
    },
};

export const frFormat = {
    number: {
        format: {
            delimiter: ' ',
            separator: ',',
        },
    },
};

export function formatNumber(number: number | string, precision?: number): string {
    return number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, frFormat.number.format.delimiter)
        .replace(/\./g, frFormat.number.format.separator);
}

export const dateRangeLocale = require('react-date-range/dist/locale');
