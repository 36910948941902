import * as React from 'react';
import Amplify from 'aws-amplify';

import { buildApolloClientAndRestoreCache, ApolloClient } from './graphql/client';
import { getDevStackMode } from '../lib/common/devStackMode';

function getGraphQlApiUrl(): { url: string; region: string } {
    if (getDevStackMode())
        return {
            url: 'https://kueq42reejcu5ompzxipy4mmwu.appsync-api.eu-west-1.amazonaws.com/graphql',
            region: 'eu-west-1',
        };
    else
        return {
            url: 'https://ym2htmlgenfxtpeg2qce4cjlqy.appsync-api.eu-west-1.amazonaws.com/graphql',
            region: 'eu-west-1',
        };
}

export function getCognitoConfiguration(): {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
} {
    if (getDevStackMode())
        return {
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_6Barv1NQL',
            userPoolWebClientId: '3r9lte4evoqsdvu1uakmqhi74p',
        };
    else
        return {
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_zkBsaAiPH',
            userPoolWebClientId: '1eoi6spclq3fbc9rc4rq26caiu',
        };
}

export async function getApolloClient(): Promise<ApolloClient> {
    const cognitoConfiguration = getCognitoConfiguration();
    const graphQlApiUrl = getGraphQlApiUrl();
    Amplify.configure({ Auth: cognitoConfiguration });
    const client = await buildApolloClientAndRestoreCache(graphQlApiUrl.url, graphQlApiUrl.region);
    return client;
}

export function useApolloClient() {
    const [apolloClient, setApolloClient] = React.useState<ApolloClient | undefined>(undefined);
    React.useEffect(() => {
        getApolloClient().then(setApolloClient);
    }, []);
    return apolloClient;
}
