import { DEV_STACK_MODE_LOCAL_STORAGE_KEY } from './devStackMode';

const LOCAL_STORAGE_KEYS_TO_KEEP = [DEV_STACK_MODE_LOCAL_STORAGE_KEY];

export function clearLocalStorageSafe(): void {
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        if (!LOCAL_STORAGE_KEYS_TO_KEEP.includes(key)) localStorage.removeItem(key);
    }
}
