import { InMemoryCacheConfig, defaultDataIdFromObject, InMemoryCache } from '@apollo/react-hooks';
import { CachePersistor } from 'apollo-cache-persist';

import { dataIdFromCustomerDashboardUser } from './fragments/dashboardUser';
import { dataIdFromCustomer } from './fragments/customer';
import { dataIdFromCustomerCampaign, dataIdFromCustomerCampaignStatistics } from './fragments/campaigns';
import { dataIdFromCustomerTransaction } from './fragments/transactions';
import { dataIdFromInsightsMerchant } from './fragments/insights';

const cacheOptions: InMemoryCacheConfig = {
    dataIdFromObject: (object: any) => {
        switch (object.__typename) {
            case 'CustomerDashboardUser':
                return dataIdFromCustomerDashboardUser(object);
            case 'Customer':
                return dataIdFromCustomer(object);
            case 'CustomerCampaign':
                return dataIdFromCustomerCampaign(object);
            case 'CustomerCampaignStatistics':
                return dataIdFromCustomerCampaignStatistics(object);
            case 'CustomerTransaction':
                return dataIdFromCustomerTransaction(object);
            case 'dataIdFromInsightsMerchant':
                return dataIdFromInsightsMerchant(object);
            default:
                return defaultDataIdFromObject(object);
        }
    },
};

export const cache: InMemoryCache = new InMemoryCache(cacheOptions);

export async function setupCachePersistor() {
    const persistor = new CachePersistor({
        cache: cache as any,
        storage: localStorage as any,
    });
    await persistor.restore();
    const cacheSize: number | null = await persistor.getSize();
    console.log(`Cache restored (cache size: ${(cacheSize || 0) / 1000}kB)`);
}
