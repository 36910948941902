import { TargetingType } from './api/graphql/fragments/campaigns';
import { CommissionStatus } from './api/graphql/fragments/transactions';
import { ImportTransactionsFromCSVError } from './api/graphql/mutations/importTransactionsFromCSV';
import { frTexts, formatNumber as frFormatNumber, dateRangeLocale as frDateRangeLocale } from './locales/fr';

export const localeTexts: LocaleTexts = frTexts;

export const formatNumber: (number: number | string) => string = frFormatNumber;

export interface LocaleTexts {
    auth: {
        signIn: {
            title: string;
            email: string;
            password: string;
            resetPassword: string;
            button: string;
            error: {
                default: string;
                wrongCredentials: string;
            };
            signUp: {
                text: string;
                button: string;
            };
        };
        signUp: {
            panel: {
                paragraph1: {
                    title: string;
                    message: string;
                };
                paragraph2: {
                    title: string;
                    message: string;
                };
                paragraph3: {
                    title: string;
                    message: string;
                };
            };
            title: string;
            firstNamePlaceholder: string;
            lastNamePlaceholder: string;
            emailPlaceholder: string;
            passwordPlaceholder: string;
            passwordConfirmationPlaceholder: string;
            passwordRequirements: {
                minCharacters: ({ minCharacters }: { minCharacters: number }) => string;
                minLowerCase: ({ minLowerCase }: { minLowerCase: number }) => string;
                minUpperCase: ({ minUpperCase }: { minUpperCase: number }) => string;
                minNumbers: ({ minNumbers }: { minNumbers: number }) => string;
            };
            passwordConfirmationError: string;
            termsOfService: string;
            signUpButton: string;
            signIn: {
                text: string;
                button: string;
            };
            error: {
                default: string;
            };
            emailVerification: {
                title: string;
                subtitle: ({ email }: { email: string }) => string;
                loading: string;
            };
        };
    };
    home: {
        navigationPanel: {
            routes: {
                campaigns: string;
                transactions: string;
                insights: string;
            };
        };
        profile: {
            navigationBar: {
                title: string;
                signOutButton: string;
            };
            userInfo: {
                credentialsSection: {
                    title: string;
                    email: string;
                    password: string;
                    passwordPlaceholder: string;
                };
                identitySection: {
                    title: string;
                    lastName: string;
                    firstName: string;
                };
            };
        };
        campaigns: {
            title: string;
            helpModal: {
                title: string;
                message: string;
                button: string;
            };
            emptyCampaignList: {
                title: string;
                subtitle: string;
            };
            campaignOfferSummary: {
                targetingType: ({ targetingType }: { targetingType: TargetingType | null }) => string;
                status: ({ status }: { status: 'active' | 'ended' | 'notStarted' }) => string;
                date: ({ startDate, endDate }: { startDate: string; endDate: string | null }) => string;
                statistics: {
                    numberOfTransactions: {
                        label: string;
                        value: ({ numberOfTransactions }: { numberOfTransactions: number }) => string;
                    };
                    averageTransactionAmount: {
                        label: string;
                        value: ({ averageTransactionAmount }: { averageTransactionAmount: number | undefined }) => string;
                    };
                    totalTransactionAmount: {
                        label: string;
                        value: ({ totalTransactionAmount }: { totalTransactionAmount: number }) => string;
                    };
                    returnOnInvestment: {
                        label: string;
                        value: ({ returnOnInvestment }: { returnOnInvestment: number | undefined }) => string;
                    };
                };
            };
        };
        transactions: {
            title: string;
            emptyTransactionList: {
                title: string;
                subtitle: string;
            };
            transactionTable: {
                header: {
                    wording: string;
                    amount: string;
                    commission: string;
                    date: string;
                    status: string;
                };
                transactionId: ({ transactionId }: { transactionId: string }) => string;
                userHashedEmail: ({ userHashedEmail }: { userHashedEmail: string | null }) => string;
                amount: ({ amount }: { amount: number }) => string;
                commission: ({ commission }: { commission: number }) => string;
                date: ({ date }: { date: string }) => string;
                status: ({ status }: { status: CommissionStatus }) => string;
            };
            transactionStatusDropDownMenu: {
                confirm: string;
                decline: string;
            };
            declineTransactionModal: {
                title: string;
                transactionId: ({ transactionId }: { transactionId: string }) => string;
                userHashedEmail: ({ userHashedEmail }: { userHashedEmail: string | null }) => string;
                amount: ({ amount }: { amount: number }) => string;
                date: ({ date }: { date: string }) => string;
                reason: string;
                cancelButton: string;
                declineButton: string;
            };
            exportButton: string;
            exportModal: {
                title: string;
                dateRange: string;
                cancelButton: string;
                exportButton: string;
            };
            importButton: string;
            importModal: {
                title: string;
                indications: string;
                fileDropZone: {
                    button: string;
                    text: string;
                };
                confirmation: {
                    message: string;
                    warning: string;
                };
                result: {
                    numberOfConfirmedTransactions: string;
                    numberOfDeclinedTransactions: string;
                    numberOfUnhandledTransactions: string;
                    error: ({ error }: { error: ImportTransactionsFromCSVError }) => string;
                };
                cancelButton: string;
                nextButton: string;
                closeButton: string;
            };
        };
        insights: {
            allMerchants: {
                title: string;
                selectMerchantsButton: ({ numberOfSelectedMerchants }: { numberOfSelectedMerchants: number }) => string;
                merchantTable: {
                    header: {
                        merchantName: string;
                        averageTransactionAmount: string;
                        averageTransactionFrequencyPerUser: string;
                        averageTransactionAmountPerUser: string;
                        totalTransactionAmountBaselineRatio: string;
                    };
                    averageTransactionAmount: ({ averageTransactionAmount }: { averageTransactionAmount: number }) => string;
                    averageTransactionFrequencyPerUser: ({
                        averageTransactionFrequencyPerUser,
                    }: {
                        averageTransactionFrequencyPerUser: number;
                    }) => string;
                    averageTransactionAmountPerUser: ({
                        averageTransactionAmountPerUser,
                    }: {
                        averageTransactionAmountPerUser: number;
                    }) => string;
                    totalTransactionAmountBaselineRatio: ({
                        totalTransactionAmountBaselineRatio,
                    }: {
                        totalTransactionAmountBaselineRatio: number;
                    }) => string;
                };
            };
            selectedMerchants: {
                title: string;
                charts: {
                    averageTransactionAmountLineChart: {
                        title: string;
                        yValue: ({ value }: { value: number }) => string;
                    };
                    totalTransactionAmountBaselineRatioLineChart: {
                        title: string;
                        yValue: ({ value }: { value: number }) => string;
                    };
                    marketShareEvolutionBarChart: {
                        title: string;
                        yValue: ({ value }: { value: number }) => string;
                        label: ({ value }: { value: number }) => string;
                    };
                    timeAxis: {
                        week: ({ week }: { week: string }) => string;
                        month: ({ month }: { month: string }) => string;
                    };
                };
            };
        };
        metabaseInsights: {
            title: string;
        };
        metabasePerformance: {
            title: string;
        };
        noCustomer: {
            title: string;
            subtitle: string;
        };
        datePicker: {
            date: ({ startDate, endDate }: { startDate: string; endDate: string }) => string;
            confirmButton: string;
        };
    };
}

export const dateRangeLocale = frDateRangeLocale;
