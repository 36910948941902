import { ApolloClient } from '../client';
import {
    customerTransactionFragment,
    CustomerTransaction,
    CommissionStatus,
    dataIdFromCustomerTransaction,
} from '../fragments/transactions';

import gql from 'graphql-tag';

const confirmTransactionMutation = gql`
    mutation ConfirmTransaction($transactionId: String!) {
        confirmTransaction(transactionId: $transactionId) {
            ...CustomerTransaction
        }
    }
    ${customerTransactionFragment}
`;

interface ConfirmTransactionMutationResponse {
    __typename: 'Mutation';
    confirmTransaction: CustomerTransaction;
}

interface ConfirmTransactionMutationVariables {
    transactionId: string;
}

export async function confirmTransaction(client: ApolloClient, transactionId: string) {
    confirmTransactionInCache(client, transactionId);
    await client.mutate<ConfirmTransactionMutationResponse, ConfirmTransactionMutationVariables>({
        mutation: confirmTransactionMutation,
        fetchPolicy: 'no-cache',
        variables: { transactionId },
    });
}

export function confirmTransactionInCache(client: ApolloClient, transactionId: string) {
    client.writeFragment<{
        __typename: 'CustomerTransaction';
        transactionId: string;
        commissionStatus: CommissionStatus;
    }>({
        id: dataIdFromCustomerTransaction({ transactionId }),
        fragment: gql`
            fragment ConfirmedCustomerTransaction on CustomerTransaction {
                transactionId
                commissionStatus
            }
        `,
        data: {
            __typename: 'CustomerTransaction',
            transactionId,
            commissionStatus: CommissionStatus.confirmed,
        },
    });
}
