import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';

import { localeTexts } from '../../../Locales';
import { useWindowSize } from '../../../style/utils';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { clearLocalStorageSafe } from '../../../lib/common/localStorage';

const texts = localeTexts.home.profile.navigationBar;

export default function ProfileNavigationBar() {
    const size = useWindowSize();
    const [signOut] = useSignOut();
    return (
        <View style={[styles.container, { width: size.width || 0 }]}>
            <View style={styles.containerHeader}>
                <Text style={styles.textTitle}>{texts.title}</Text>
                <TouchableOpacity style={styles.containerSignOutButton} onPress={signOut}>
                    <Text style={styles.textSignOutButton}>{texts.signOutButton}</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.containerBorderBottom} />
        </View>
    );
}

function useSignOut(): [() => void, boolean] {
    const apolloClient = useApolloClient();
    const [isSigningOut, setIsSigningOut] = React.useState(false);
    const signOut = async () => {
        setIsSigningOut(true);
        try {
            await Auth.signOut();
            await apolloClient.clearStore();
            clearLocalStorageSafe();
            window.location.reload();
        } catch (error) {
            console.log(error);
            setIsSigningOut(false);
        }
    };
    return [signOut, isSigningOut];
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 48,
        paddingTop: 50,
    },
    containerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 24,
    },
    containerSignOutButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        paddingHorizontal: 16,
        borderRadius: 48,
        backgroundColor: color.emerald,
    },
    containerBorderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: color.athensGrayDark,
    },
    textTitle: {
        fontFamily: font.ambitRegular,
        fontSize: 24,
        color: color.tundora,
    },
    textSignOutButton: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.white,
    },
});
