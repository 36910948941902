import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    metabaseDashboardSignedUrlQuery,
    MetabaseDashboardSignedUrlQueryResponse,
    MetabaseDashboardSignedUrlQueryVariables,
} from '../../api/graphql/queries/metabase';

export function useMetabaseDashboardSignedUrl(dashboardType: DashboardType): string | undefined {
    const queryResponse = useQuery<MetabaseDashboardSignedUrlQueryResponse, MetabaseDashboardSignedUrlQueryVariables>(
        metabaseDashboardSignedUrlQuery,
        {
            fetchPolicy: 'no-cache',
            variables: { dashboardType },
        }
    );
    const metabaseDashboardSignedUrl = React.useMemo(
        () => queryResponse?.data?.customer?.metabaseDashboardSignedUrl || undefined,
        [queryResponse]
    );
    return metabaseDashboardSignedUrl;
}

export enum DashboardType {
    insights = 'Insights',
    performance = 'Performance',
}
