import { StyleSheet, View, Text } from 'react-native';

import { localeTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { useCustomerDashboardUser } from '../../../lib/dashboardUser/dashboardUser';

const texts = localeTexts.home.profile.userInfo;

export default function UserInfoPage() {
    const customerDashboardUser = useCustomerDashboardUser();
    if (!customerDashboardUser) return null;
    return (
        <View>
            <View style={styles.containerSection}>
                <Text style={styles.textSectionTitle}>{texts.credentialsSection.title}</Text>
                <Text style={styles.textLabel}>{texts.credentialsSection.email}</Text>
                <View style={styles.containerValue}>
                    <Text style={styles.textValue}>{customerDashboardUser.email}</Text>
                </View>
                <Text style={styles.textLabel}>{texts.credentialsSection.password}</Text>
                <View style={styles.containerValue}>
                    <Text style={styles.textValue}>{texts.credentialsSection.passwordPlaceholder}</Text>
                </View>
            </View>
            <View style={styles.containerSection}>
                <Text style={styles.textSectionTitle}>{texts.identitySection.title}</Text>
                <Text style={styles.textLabel}>{texts.identitySection.lastName}</Text>
                <View style={styles.containerValue}>
                    <Text style={styles.textValue}>{customerDashboardUser.lastName || ''}</Text>
                </View>
                <Text style={styles.textLabel}>{texts.identitySection.firstName}</Text>
                <View style={styles.containerValue}>
                    <Text style={styles.textValue}>{customerDashboardUser.firstName || ''}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerSection: {
        marginTop: 40,
        marginHorizontal: 48,
    },
    containerValue: {
        marginTop: 4,
        justifyContent: 'center',
        height: 36,
        width: 400,
        paddingHorizontal: 16,
        borderRadius: 8,
        backgroundColor: color.athensGray,
    },
    textSectionTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.tundora,
    },
    textLabel: {
        marginTop: 16,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
    textValue: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.tundora,
    },
});
