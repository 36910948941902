import { CustomerDashboardUser, customerDashboardUserFragment } from '../fragments/dashboardUser';

import gql from 'graphql-tag';

export const customerDashboardUserQuery = gql`
    query CustomerDashboardUserQuery {
        customerDashboardUser {
            ...CustomerDashboardUser
        }
    }
    ${customerDashboardUserFragment}
`;

export type CustomerDashboardUserQueryResponse = {
    __typename: 'Query';
    customerDashboardUser: CustomerDashboardUser;
};
