import gql from 'graphql-tag';

export const customerDashboardUserFragment = gql`
    fragment CustomerDashboardUser on CustomerDashboardUser {
        customerDashboardUserId
        email
        firstName
        lastName
        customerId
    }
`;

export interface CustomerDashboardUser {
    __typename: 'CustomerDashboardUser';
    customerDashboardUserId: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    customerId: string | null;
}

export const dataIdFromCustomerDashboardUser = (user?: CustomerDashboardUser) => {
    return `CustomerDashboardUser:current`;
};
