import React from 'react';
import { StyleSheet, View, Image, Text, TextInput, TouchableOpacity } from 'react-native';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';

import { privacyPolicyLink, termsOfServiceLink } from '../../Version';
import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { getDevStackMode } from '../../lib/common/devStackMode';
import { usePrevious } from '../../lib/common/utilHooks';
import Button from '../../components/common/Button';
import HighlightedText from '../../components/common/HighlightedText';
import FloatingIcon from '../../components/common/FloatingIcon';
import PasswordRequirements, { usePasswordRequirementsChecks } from '../../components/auth/PasswordRequirements';

const { v1: uuidv1 } = require('uuid');

const logo = { uri: '/assets/images/logos/logo-with-title.png' };
const checkMarkGreenIcon = { uri: '/assets/images/icons/check-mark-green-filled.svg' };
const checkMarkWhiteIcon = { uri: '/assets/images/icons/check-mark.svg' };
const handHoldingBillsIllustration = { uri: '/assets/images/illustrations/hand-holding-bills.png' };
const eyeIcon = { uri: '/assets/images/icons/eye.svg' };
const eyeSlashIcon = { uri: '/assets/images/icons/eye-slash.svg' };
const alertIcon = { uri: '/assets/images/icons/alert-red.svg' };
const currencySymbol = { uri: '/assets/images/icons/currency-symbol-yellow.svg' };
const ticketIcon = { uri: '/assets/images/icons/ticket.svg' };
const giftIcon = { uri: '/assets/images/icons/gift-green-tilted.svg' };

const texts = localeTexts.auth.signUp;

export default function SignUpPage() {
    const history = useHistory();
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>('');
    const previousFirstName = usePrevious(firstName);
    const previousLastName = usePrevious(lastName);
    const previousEmail = usePrevious(email);
    const previousPassword = usePrevious(password);
    const passwordRequirementsChecks = usePasswordRequirementsChecks(password);
    const [consentedToTermsOfService, setConsentedToTermsOfService] = React.useState<boolean>(false);
    const [signUp, isLoading, isEmailVerificationNeeded, error, clearError] = useSignUp();
    const canSignUp = React.useMemo(
        () =>
            firstName.length > 0 &&
            lastName.length > 0 &&
            isEmailValid(email) &&
            consentedToTermsOfService &&
            passwordConfirmation === password &&
            passwordRequirementsChecks.all,
        [firstName, lastName, email, consentedToTermsOfService, password, passwordConfirmation, passwordRequirementsChecks]
    );
    const onPress = () => {
        if (canSignUp) signUp(firstName, lastName, email, password);
    };
    React.useEffect(() => {
        if (
            firstName.length !== previousFirstName?.length ||
            lastName.length !== previousLastName?.length ||
            email.length !== previousEmail?.length ||
            password.length !== previousPassword?.length
        )
            clearError();
    }, [
        firstName,
        lastName,
        email,
        password,
        previousFirstName,
        previousLastName,
        previousEmail,
        previousPassword,
        clearError,
    ]);
    if (!isEmailVerificationNeeded)
        return (
            <View style={styles.container}>
                <Panel />
                <View style={{ flex: 1 }}>
                    <View style={styles.containerContent}>
                        <Text style={styles.textTitle}>{texts.title}</Text>
                        <View style={styles.containerField}>
                            <FirstNameInput {...{ firstName, setFirstName, isLoading }} />
                        </View>
                        <View style={styles.containerField}>
                            <LastNameInput {...{ lastName, setLastName, isLoading }} />
                        </View>
                        <View style={styles.containerField}>
                            <EmailInput {...{ email, setEmail, isLoading }} />
                        </View>
                        <View style={styles.containerField}>
                            <PasswordInput {...{ password, setPassword, isLoading }} />
                            {password.length > 0 ? (
                                <View style={styles.containerPasswordRequirements}>
                                    <PasswordRequirements {...{ passwordRequirementsChecks }} />
                                </View>
                            ) : null}
                        </View>
                        <View style={styles.containerField}>
                            <PasswordInput
                                password={passwordConfirmation}
                                setPassword={setPasswordConfirmation}
                                placeholder={texts.passwordConfirmationPlaceholder}
                                {...{ isLoading }}
                            />
                            {passwordConfirmation.length > 0 && password !== passwordConfirmation ? (
                                <View style={styles.containerPasswordConfirmationError}>
                                    <Image source={alertIcon} style={{ width: 12, height: 12, resizeMode: 'contain' }} />
                                    <Text style={styles.textPasswordConfirmationError}>
                                        {texts.passwordConfirmationError}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                        <TermsOfServiceConsentCheckBox
                            isChecked={consentedToTermsOfService}
                            setIsChecked={setConsentedToTermsOfService}
                        />
                        {error ? <ErrorMessage {...{ error }} /> : null}
                        <Button
                            {...{ onPress, isLoading }}
                            textStyle={styles.textSignUpButton}
                            style={styles.containerSignUpButton}
                            height={40}
                            disabled={!canSignUp}>
                            {texts.signUpButton}
                        </Button>
                        <Text style={styles.textSignIn}>{texts.signIn.text}</Text>
                        <TouchableOpacity onPress={() => history.push('/auth/sign-in')}>
                            <Text style={styles.textSignInButton}>{texts.signIn.button}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    else return <EmailVerificationMessage {...{ email, password }} />;
}

enum SignUpError {
    Other = 'Other',
}

function useSignUp(): [
    (firstName: string, lastName: string, email: string, password: string) => Promise<void>,
    boolean,
    boolean,
    SignUpError | undefined,
    () => void
] {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isEmailVerificationNeeded, setIsEmailVerificationNeeded] = React.useState<boolean>(false);
    const [error, setError] = React.useState<SignUpError | undefined>();
    const signUp = async (firstName: string, lastName: string, email: string, password: string): Promise<void> => {
        if (isEmailValid(email)) {
            setIsLoading(true);
            try {
                const userId: string = uuidv1();
                await Auth.signUp({
                    username: userId,
                    password,
                    attributes: { email: email.toLowerCase(), family_name: lastName, given_name: firstName },
                });
                setIsEmailVerificationNeeded(true);
            } catch (error) {
                console.log(error);
                setError(SignUpError.Other);
            }
            setIsLoading(false);
        }
    };
    const clearError = () => setError(undefined);
    return [signUp, isLoading, isEmailVerificationNeeded, error, clearError];
}

export function isEmailValid(email: string): boolean {
    const regex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return regex.test(email.toLowerCase());
}

function Panel() {
    const size = useWindowSize();
    const devStackMode = getDevStackMode();
    return (
        <View style={[styles.containerPanel, devStackMode ? { backgroundColor: color.fairPink } : {}]}>
            <View style={{ height: size.height }}>
                <View style={styles.containerPanelContent}>
                    <View style={styles.containerPanelLogo}>
                        <Image source={logo} style={styles.imagePanelLogo} />
                    </View>
                    <View style={styles.containerPanelParagraphs}>
                        <PanelParagraph title={texts.panel.paragraph1.title} message={texts.panel.paragraph1.message} />
                        <PanelParagraph title={texts.panel.paragraph2.title} message={texts.panel.paragraph2.message} />
                        <PanelParagraph title={texts.panel.paragraph3.title} message={texts.panel.paragraph3.message} />
                    </View>
                </View>
                <View style={styles.containerPanelIllustration}>
                    <Image source={handHoldingBillsIllustration} style={styles.imagePanelIllustration} />
                </View>
            </View>
        </View>
    );
}

function PanelParagraph({ title, message }: { title: string; message: string }) {
    return (
        <View style={styles.containerPanelParagraph}>
            <View style={styles.containerPanelCheckMarkIcon}>
                <Image source={checkMarkGreenIcon} style={styles.imagePanelCheckMark} />
            </View>
            <View style={styles.containerPanelText}>
                <Text style={styles.textPanelTitle}>{title}</Text>
                <Text style={styles.textPanelMessage}>{message}</Text>
            </View>
        </View>
    );
}

function FirstNameInput({
    firstName,
    setFirstName,
    isLoading,
}: {
    firstName: string;
    setFirstName: (firstName: string) => void;
    isLoading: boolean;
}) {
    return (
        <View style={[firstName ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.firstNamePlaceholder}
                value={firstName}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setFirstName}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

function LastNameInput({
    lastName,
    setLastName,
    isLoading,
}: {
    lastName: string;
    setLastName: (lastName: string) => void;
    isLoading: boolean;
}) {
    return (
        <View style={[lastName ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.lastNamePlaceholder}
                value={lastName}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setLastName}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

function EmailInput({
    email,
    setEmail,
    isLoading,
}: {
    email: string;
    setEmail: (email: string) => void;
    isLoading: boolean;
}) {
    return (
        <View style={[email ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                value={email}
                style={styles.textInput}
                placeholder={texts.emailPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                returnKeyType="next"
                editable={!isLoading}
                onChangeText={setEmail}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

function PasswordInput({
    password,
    setPassword,
    isLoading,
    placeholder,
}: {
    password: string;
    setPassword: (password: string) => void;
    isLoading: boolean;
    placeholder?: string;
}) {
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    return (
        <View style={[password ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={placeholder || texts.passwordPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                secureTextEntry={hidePassword}
                underlineColorAndroid="transparent"
                returnKeyType="go"
                editable={!isLoading}
                onChangeText={setPassword}
                placeholderTextColor={color.frenchGray}
            />
            <TouchableOpacity
                onPress={() => setHidePassword(!hidePassword)}
                activeOpacity={0.8}
                style={{ justifyContent: 'center', position: 'absolute', right: 5, top: 5 }}>
                {hidePassword ? (
                    <Image source={eyeIcon} style={styles.imageEyeIcon} />
                ) : (
                    <Image source={eyeSlashIcon} style={styles.imageEyeIcon} />
                )}
            </TouchableOpacity>
        </View>
    );
}

export function TermsOfServiceConsentCheckBox({
    isChecked,
    setIsChecked,
}: {
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}) {
    const viewTermsOfService = () => window.open(termsOfServiceLink);
    const viewPrivacyPolicy = () => window.open(privacyPolicyLink);
    return (
        <CheckBox {...{ isChecked, setIsChecked }}>
            <HighlightedText
                style={styles.textCheckBox}
                highlightedStyle={styles.textCheckBoxHighlighted}
                onPressHighlightedTexts={[viewTermsOfService, viewPrivacyPolicy]}>
                {texts.termsOfService}
            </HighlightedText>
        </CheckBox>
    );
}

function CheckBox({
    children,
    isChecked,
    setIsChecked,
}: {
    children: JSX.Element;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}) {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
                onPress={() => setIsChecked(!isChecked)}
                activeOpacity={0.7}
                style={[
                    styles.containerCheckMark,
                    isChecked ? styles.containerCheckMarkSelected : styles.containerCheckMarkUnselected,
                ]}>
                {isChecked ? (
                    <Image source={checkMarkWhiteIcon} style={{ height: 12, width: 12, resizeMode: 'contain' }} />
                ) : null}
            </TouchableOpacity>
            {children}
        </View>
    );
}

export function ErrorMessage({ error }: { error: SignUpError }) {
    let message: string = texts.error.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
}

export function EmailVerificationMessage({ email, password }: { email: string; password: string }) {
    const size = useWindowSize();
    const isLoading = useSignInAttempt({ email, password });
    return (
        <View style={[styles.containerEmailVerification, { height: size.height }]}>
            <View style={styles.containerEmailVerificationLogo}>
                <Image source={logo} style={styles.imageEmailVerificationLogo} />
            </View>
            {!isLoading ? (
                <View style={styles.containerEmailVerificationMessage}>
                    <Text style={styles.textEmailVerificationTitle}>{texts.emailVerification.title}</Text>
                    <HighlightedText
                        style={styles.textEmailVerificationSubtitle}
                        highlightedStyle={styles.textEmailVerificationSubtitleHighlighted}>
                        {texts.emailVerification.subtitle({ email })}
                    </HighlightedText>
                </View>
            ) : (
                <>
                    <View style={[styles.containerEmailVerificationLoadingIcons, { top: (size.height || 0) / 2 - 70 }]}>
                        <FloatingIcon icon={currencySymbol} />
                        <FloatingIcon icon={ticketIcon} offsetY={-10} />
                        <FloatingIcon icon={giftIcon} />
                    </View>
                    <View style={[styles.containerEmailVerificationLoadingText, { top: (size.height || 0) / 2 }]}>
                        <Text style={styles.textEmailVerificationLoading}>{texts.emailVerification.loading}</Text>
                    </View>
                </>
            )}
        </View>
    );
}

function useSignInAttempt({ email, password }: { email: string; password: string }) {
    const [isLoading, setIsLoading] = React.useState(false);
    const signIn = React.useCallback(async () => {
        setIsLoading(true);
        try {
            await Auth.signIn({ username: email, password });
            window.location.reload();
        } catch (error) {}
        setIsLoading(false);
    }, [email, password]);
    React.useEffect(() => {
        window.addEventListener('visibilitychange', signIn);
        return () => {
            window.addEventListener('visibilitychange', signIn);
        };
    }, [signIn]);
    return isLoading;
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    containerPanel: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: color.linkWaterLight,
    },
    containerPanelContent: {
        position: 'absolute',
        top: 60,
        right: 80,
        width: 300,
    },
    containerPanelLogo: {
        marginLeft: 15,
    },
    containerPanelParagraphs: {
        marginTop: 40,
    },
    containerPanelParagraph: {
        flexDirection: 'row',
    },
    containerPanelText: {
        flex: 1,
        marginLeft: 12,
    },
    containerPanelCheckMarkIcon: {
        top: 2,
    },
    containerPanelIllustration: {
        position: 'absolute',
        bottom: 0,
        left: -130,
    },
    containerContent: {
        marginTop: 64,
        marginLeft: 80,
        width: 376,
    },
    containerField: {
        height: 95,
    },
    containerTextInput: {
        flexDirection: 'row',
    },
    containerPasswordRequirements: {
        marginTop: 8,
    },
    containerPasswordConfirmationError: {
        flexDirection: 'row',
        marginTop: 8,
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
        borderRadius: 24,
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.frenchGray,
    },
    containerErrorMessage: {
        backgroundColor: color.fairPink,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 16,
    },
    containerSignUpButton: {
        marginTop: 56,
    },
    containerEmailVerification: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.linkWaterLight,
    },
    containerEmailVerificationLogo: {
        position: 'absolute',
        top: 54,
    },
    containerEmailVerificationMessage: {
        width: 440,
        paddingHorizontal: 32,
        paddingVertical: 48,
        backgroundColor: color.white,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.06,
        shadowRadius: 12,
    },
    containerEmailVerificationLoadingIcons: {
        position: 'absolute',
        flexDirection: 'row',
    },
    containerEmailVerificationLoadingText: {
        position: 'absolute',
        alignItems: 'center',
        backgroundColor: color.whisper,
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderRadius: 8,
    },
    textPanelTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.codGray,
        marginBottom: 8,
    },
    textPanelMessage: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.manatee,
        marginBottom: 25,
    },
    textTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 20,
        color: color.codGray,
        marginBottom: 42,
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitRegular,
        color: color.tundora,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textPasswordConfirmationError: {
        marginLeft: 4,
        fontFamily: font.ambitRegular,
        color: color.radicalRed,
        fontSize: 12,
    },
    textCheckBox: {
        fontFamily: font.ambitRegular,
        color: color.tundora,
        fontSize: 14,
        paddingLeft: 15,
    },
    textCheckBoxHighlighted: {
        fontFamily: font.ambitSemiBold,
        color: color.emerald,
    },
    textErrorMessage: {
        color: color.radicalRed,
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textSignUpButton: {
        fontSize: 14,
    },
    textSignIn: {
        marginTop: 24,
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.manatee,
    },
    textSignInButton: {
        marginTop: 8,
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.emerald,
    },
    textEmailVerificationTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 20,
        color: color.codGray,
        marginBottom: 24,
    },
    textEmailVerificationSubtitle: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.osloGray,
    },
    textEmailVerificationSubtitleHighlighted: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.osloGray,
    },
    textEmailVerificationLoading: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.codGray,
    },
    imagePanelLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
    imagePanelCheckMark: {
        width: 16,
        height: 16,
    },
    imagePanelIllustration: {
        height: 332,
        width: 483,
        resizeMode: 'contain',
    },
    imageEyeIcon: {
        width: 25,
        height: 25,
        resizeMode: 'contain',
    },
    imageEmailVerificationLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.tundora,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
});
