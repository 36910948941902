import { ApolloClient } from '../client';
import {
    customerTransactionFragment,
    CustomerTransaction,
    CommissionStatus,
    dataIdFromCustomerTransaction,
} from '../fragments/transactions';

import gql from 'graphql-tag';

const declineTransactionMutation = gql`
    mutation DeclineTransaction($transactionId: String!, $reason: String!) {
        declineTransaction(transactionId: $transactionId, reason: $reason) {
            ...CustomerTransaction
        }
    }
    ${customerTransactionFragment}
`;

interface DeclineTransactionMutationResponse {
    __typename: 'Mutation';
    declineTransaction: CustomerTransaction;
}

interface DeclineTransactionMutationVariables {
    transactionId: string;
    reason: string;
}

export async function declineTransaction(client: ApolloClient, transactionId: string, reason: string) {
    declineTransactionInCache(client, transactionId);
    await client.mutate<DeclineTransactionMutationResponse, DeclineTransactionMutationVariables>({
        mutation: declineTransactionMutation,
        fetchPolicy: 'no-cache',
        variables: { transactionId, reason },
    });
}

export function declineTransactionInCache(client: ApolloClient, transactionId: string) {
    client.writeFragment<{
        __typename: 'CustomerTransaction';
        transactionId: string;
        commissionStatus: CommissionStatus;
    }>({
        id: dataIdFromCustomerTransaction({ transactionId }),
        fragment: gql`
            fragment DeclinedCustomerTransaction on CustomerTransaction {
                transactionId
                commissionStatus
            }
        `,
        data: {
            __typename: 'CustomerTransaction',
            transactionId,
            commissionStatus: CommissionStatus.declined,
        },
    });
}
