import { View, StyleSheet, Text, Image } from 'react-native';

import { localeTexts } from '../../Locales';
import color from '../../style/color';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';

const texts = localeTexts.home.noCustomer;

const clipboardIllustration = { uri: '/assets/images/illustrations/clipboard.svg' };

export default function NoCustomerPage() {
    const size = useWindowSize();
    return (
        <View style={[styles.container, { height: (size.height || 0) - NAVIGATION_BAR_HEIGHT, width: size.width }]}>
            <Image source={clipboardIllustration} style={styles.image} />
            <Text style={styles.textTitle}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    textTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 20,
        color: color.codGray,
        textAlign: 'center',
        marginTop: 27,
        marginBottom: 24,
    },
    textSubtitle: {
        width: 375,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.osloGray,
        textAlign: 'center',
    },
    image: {
        width: 100,
        height: 100,
        resizeMode: 'contain',
    },
});
